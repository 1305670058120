import WebService from '../../Services/WebServices';

import {
  JOB_DATA_LOADING,
  JOB_DATA_FAILED,
  JOB_DATA_SUCCESS,
  JOB_DATA_RELOAD,
} from '../../Redux/ActionTypes';
import { JOBS, JOBS_DETAILS } from '../../Constants/options';

export const getJobById = (scheduleId) => async (dispatch) => {
  dispatch({ type: JOB_DATA_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${JOBS}/${scheduleId}${JOBS_DETAILS}`,
    };

    const jobData = await WebService.getSharedInstance().callWebservice(
      options,
    );
    dispatch({
      type: JOB_DATA_SUCCESS,
      payload: jobData
    });

  } catch (err) {
    dispatch({
      type: JOB_DATA_FAILED,
      payload: {
        err
      }
    });
  }
};

export const setStateToDefaultJob = () => {
  return {
    type: JOB_DATA_RELOAD,
  }
}
