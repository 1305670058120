import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TopTitle from '../../Components/TopTitle/TopTitle';
import useLocalized from '../../Data/Localization';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AcceptedDriverTableCellBody from '../../Components/AcceptedDriverTableCellBody/AcceptedDriverTableCellBody';
import InactiveDriverTableCellBody from '../../Components/InactiveDriverTableCellBody/InactiveDriverTableCellBody';
import PendingDriverTableCellBody from '../../Components/PendingDriverTableCellBody/PendingDriverTableCellBody';

const DriversSelector = ({
  drivers,
}) => {
  const labelNr = useLocalized('drivers_table_head_cell_nr');
  const labelDriverName = useLocalized('drivers_table_head_cell_driver_name');
  const labelLocation = useLocalized('drivers_table_head_cell_location');
  const labelGrade = useLocalized('drivers_table_head_cell_grade');
  const labelPhone = useLocalized('drivers_table_head_cell_phone');
  const labelOrder = useLocalized('drivers_table_head_cell_nr');
  const labelAccept = useLocalized('drivers_accept_label');
  const labelReject = useLocalized('drivers_decline_label');
  const labelDetails = useLocalized('details_button');
  const labelRemove = useLocalized('remove_button');
  const emptyLeaversListMessage = useLocalized('drive_leavers_empty_message');

  const [statusTypeActive, setPresentedDrivers] = useState([]);
  const [statusTypePending, setPresentedPendingDrivers] = useState([]);
  const [statusTypeLeavers, setPresentedLeaverDrivers] = useState([]);
  const [activePageValues, setActivePageValues] = useState({
    page: 0,
    rowsPerPage: 100,
  });
  const [leaversPageValues, setLeaversPage] = useState({
    page: 0,
    rowsPerPage: 100,
  });
  const [inactivePageValues, setInactivePageValues] = useState({
    page: 0,
    rowsPerPage: 100,
  });

  const paginatorProperties = {
    ROWS_PER_PAGE: [100, 200]
  }
  
  const driverTableTypes = {
    INACTIVE: 'Inactive',
    LEAVERS: 'Leavers',
    ACTIVE: 'Active'
  }
  
  useEffect(() => {
    if (drivers) {
      setPresentedDrivers(drivers.filter(({ status }) => status.includes("Active")));
      setPresentedPendingDrivers(drivers.filter(({ status }) => status.includes("Pending")));
      setPresentedLeaverDrivers(drivers.filter(({ status }) => status.includes("Deleted")));
    }
  }, [drivers]);

  const inactiveColumns = [
    { id: 'colorCode', label: '', class: 'colorCode-cell table-list-head-cell' },
    { id: 'number', label: labelOrder, class: 'columns-grid table-list-head-cell nr-cell-inactive' },
    { id: 'name', label: labelDriverName, class: 'driver-name columns-grid table-list-head-cell driver-name-cell-inactive' },
    { id: 'phone', label: labelPhone, class: 'columns-grid phone-cell table-list-head-cell' },
    { id: 'id', label: '', class: 'id-inactive-cell table-list-head-cell' },
  ];

  const leaverColumns = [
    { id: 'colorCode', label: '', class: 'colorCode-cell table-list-head-cell' },
    { id: 'number', label: labelNr, class: 'columns-grid nr-cell table-list-head-cell' },
    { id: 'name', label: labelDriverName, class: 'driver-name columns-grid driver-name-cell table-list-head-cell' },
    { id: 'location', label: labelLocation, class: 'columns-grid location-cell table-list-head-cell' },
    { id: 'grade', label: labelGrade, class: 'columns-grid grade-cell table-list-head-cell' },
    { id: 'id', label: '', class: 'edit-cell table-list-head-cell' },
  ];

  const columns = [
    { id: 'colorCode', label: '', class: 'colorCode-cell table-list-head-cell' },
    { id: 'number', label: labelNr, class: 'columns-grid nr-cell table-list-head-cell' },
    { id: 'name', label: labelDriverName, class: 'driver-name columns-grid driver-name-cell table-list-head-cell' },
    { id: 'location', label: labelLocation, class: 'columns-grid location-cell table-list-head-cell' },
    { id: 'grade', label: labelGrade, class: 'columns-grid grade-cell table-list-head-cell' },
    { id: 'id', label: '', class: 'edit-cell table-list-head-cell' },
  ];     

  const pagingCalculation = useCallback((type, values) => {
    const pageNumbers = {start: values.page * values.rowsPerPage, end: values.page * values.rowsPerPage + values.rowsPerPage}

    switch(type) {
      case driverTableTypes.ACTIVE:
        return statusTypeActive.slice(pageNumbers.start, pageNumbers.end)
      case driverTableTypes.LEAVERS:
        return statusTypeLeavers.slice(pageNumbers.start, pageNumbers.end)
      case driverTableTypes.INACTIVE:
        return statusTypePending.slice(pageNumbers.start, pageNumbers.end)
    }
  }, [statusTypeActive, driverTableTypes, statusTypePending])

  return (
    <>
      <Paper className="default-table-container">
        <TableContainer className="default-table-list">
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead className="table-list-head">
              <TableRow>
                {columns.map((column) => (
                  <TableCell className={column.class}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="default-table-body">
              {pagingCalculation(driverTableTypes.ACTIVE, activePageValues).map((row, j) => {
                return (
                  <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={j + 1}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell className={column.class} key={column.id}>
                          <AcceptedDriverTableCellBody value={value} column={column} details={labelDetails} remove={labelRemove} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={paginatorProperties.ROWS_PER_PAGE}
          component="div"
          count={statusTypeActive.length}
          rowsPerPage={activePageValues.rowsPerPage}
          page={activePageValues.page}
          onChangePage={(e) => setActivePageValues({...activePageValues, page: e.target.value})}
          onChangeRowsPerPage={(e) => setActivePageValues({...activePageValues, rowsPerPage: e.target.value, page: 0})}
        />
      </Paper>
      <div className="driver-inactive-registration-list">
        <TopTitle title={`${useLocalized('drive_leavers_tile')}`} subtitle={`${useLocalized('driver_leavers_message')}`} />
        {statusTypeLeavers.length > 0 ? <Paper className="default-table-container">
          <TableContainer className="default-table-list">
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className="table-list-head">
                <TableRow>
                  {leaverColumns.map((column) => (
                    <TableCell className={column.class}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="default-table-body">
                {pagingCalculation(driverTableTypes.LEAVERS, leaversPageValues).map((row, j) => {
                  return (
                    <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={j + 1}>
                      {leaverColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell className={column.class} key={column.id}>
                            <PendingDriverTableCellBody value={value} column={column} details={labelDetails} remove={labelRemove} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={paginatorProperties.ROWS_PER_PAGE}
            component="div"
            count={statusTypeLeavers.length}
            rowsPerPage={leaversPageValues.rowsPerPage}
            page={leaversPageValues.page}
            onChangePage={(e) => setLeaversPage({...leaversPageValues, page: e.target.value})}
            onChangeRowsPerPage={(e) => setLeaversPage({...leaversPageValues, rowsPerPage: e.target.value, page: 0})}
          />
        </Paper> : <div className='text'>{emptyLeaversListMessage}</div>}
      </div>
      <div className="driver-inactive-registration-list">
        <TopTitle title={`${useLocalized('driver_in_queue_tile')}`} subtitle={`${useLocalized('driver_in_queue_message')}`} />
        <Paper className="default-table-container">
          <TableContainer className="default-table-list">
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className="table-list-head">
                <TableRow>
                  {inactiveColumns.map((column) => (
                    <TableCell className="table-list-head-cell"
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="default-table-body">
                {pagingCalculation(driverTableTypes.INACTIVE, inactivePageValues).map((row, i) => {
                  return (
                    <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={i + 1}>
                      {inactiveColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell className={column.class} key={column.id}>
                            <InactiveDriverTableCellBody value={value} column={column} accept={labelAccept} reject={labelReject} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={paginatorProperties.ROWS_PER_PAGE}
            component="div"
            count={statusTypePending.length}
            rowsPerPage={inactivePageValues.rowsPerPage}
            page={inactivePageValues.page}
            onChangePage={(e) => setInactivePageValues({...inactivePageValues, page: e.target.value})}
            onChangeRowsPerPage={(e) => setInactivePageValues({...inactivePageValues, rowsPerPage: e.target.value, page: 0})}
          />
        </Paper>
      </div>
    </>
  );
};


export default DriversSelector;
