import { useEffect } from 'react';
import { FAIL, DEFAULT } from '../Redux/ActionTypes';
import useLocalizedErrorMessage from './useLocalizedErrorMessage'

export const useErrorHandler = (status, errorCode, withAlert) => {
  const errorMessage = useLocalizedErrorMessage(errorCode);

  useEffect(() => {
    if (status === FAIL && withAlert !== false) {
      alert(errorMessage)
    }
  });
  return errorMessage;
};

export const useErrorHandlerWithAlertAndPageReload = (status, errorCode) => {
  const errorMessage = useLocalizedErrorMessage(errorCode);
  useEffect(() => {
    if (status === DEFAULT && errorCode) {
      alert(errorMessage)
    }
  }, [status, errorCode, errorMessage]);
  return errorMessage;
};
