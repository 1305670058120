import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useLocalized from '../../Data/Localization';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PartnerListCell from '../PartnerListCell/PartnerListCell';
import { PartnerListColumnsTypes } from '../../Constants/partnerListColumnTypes';

const PartnerList = ({
  presentedPartners,
  handlePartnerDataChange
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  }, []);

  const columns = [
    { id: PartnerListColumnsTypes.NR, label: `${useLocalized('partners_table_head_cell_nr')}`, class: 'columns-grid nr-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.COMPANY_NAME, label: `${useLocalized('partners_table_head_cell_company_name')}`, class: 'driver-name columns-grid partner-name-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.LOCATION, label: `${useLocalized('partners_table_head_cell_location')}`, class: 'columns-grid location-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.PARTNER_SINCE, label: `${useLocalized('partners_table_head_cell_partner_since')}`, class: 'columns-grid partner-since-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_VAN, label: `${useLocalized('partners_table_head_cell_van_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN, label: `${useLocalized('partners_table_head_cell_van_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_VAN_PAYROLL, label: `${useLocalized('partners_table_head_cell_van_payroll_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN_PAYROLL, label: `${useLocalized('partners_table_head_cell_van_payroll_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_HIAB, label: `${useLocalized('partners_table_head_cell_hiab_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB, label: `${useLocalized('partners_table_head_cell_hiab_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_HIAB_PAYROLL, label: `${useLocalized('partners_table_head_cell_hiab_payroll_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB_PAYROLL, label: `${useLocalized('partners_table_head_cell_hiab_payroll_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_SEVEN_TONS, label: `${useLocalized('partners_table_head_cell_seven_tons_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SEVEN_TONS, label: `${useLocalized('partners_table_head_cell_seven_tons_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_LOFT_L, label: `${useLocalized('partners_table_head_cell_loft_l_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_LOFT_L, label: `${useLocalized('partners_table_head_cell_loft_l_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: '', label: '', class: 'columns-grid divider-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.HOURLY_RATE_SPECIAL, label: `${useLocalized('partners_table_head_cell_special_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
    { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SPECIAL, label: `${useLocalized('partners_table_head_cell_special_overtime_hourly_rate')}`, class: 'columns-grid rate-cell table-list-head-cell' },
  ];

  return (
    <Paper className="default-table-container">
      <TableContainer className="default-table-list-partner">
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead className="table-list-head">
            <TableRow>
              {columns.map((column) => (
                <TableCell className={column.class} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="default-table-body">
            {presentedPartners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, j) => {
              return (
                <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={j + 1}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell className={column.class} key={column.id}>
                      <PartnerListCell column={column} value={value} j={page * rowsPerPage + j} partners={presentedPartners} onChange={handlePartnerDataChange} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="table-pagination"
        rowsPerPageOptions={[12, 25, 100]}
        component="div"
        count={presentedPartners.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

PartnerList.propTypes = {
  partners: PropTypes.array,
};

export default PartnerList;
