import {
    JOB_DATA_LOADING,
    JOB_DATA_SUCCESS,
    JOB_DATA_FAILED,
    DEFAULT,
    LOADING,
    SUCCESS,
    FAIL,
    JOB_DATA_RELOAD,
} from '../ActionTypes';

const initialState = {
    status: DEFAULT,
    data: [],
    errorCode: '',
}

const jobDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case JOB_DATA_LOADING:
            return {
                ...state,
                status: LOADING,
            };
        case JOB_DATA_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                data: payload,
            };
        case JOB_DATA_FAILED:
            return {
                ...state,
                status: FAIL,
                errorCode: payload.err
            };
        case JOB_DATA_RELOAD:
            return {
                ...state,
                status: DEFAULT,
            };
        default:
            return state;
    }
};

export default jobDataReducer;
