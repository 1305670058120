import React, { useCallback, useEffect } from 'react';
import useLocalized from '../../Data/Localization';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TopTitle from '../../Components/TopTitle/TopTitle';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import OrderPopup from '../../Components/OrderPopup/OrderPopup';
import { useErrorHandler, useWebServiceWithBreak } from '../../CustomHooks';
import { getOrders, deleteOrder, createOrder } from '../../Redux/Actions/OrderActions';
import { SUCCESS, LOADING, FAIL } from '../../Redux/ActionTypes';
import OrderSelector from '../../Components/OrderSelector/OrderSelector';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getPartners } from '../../Redux/Actions/PartnerActions';

const Orders = ({
  orders: {
    status: ordersStatus,
    data: ordersData,
    errorCode: ordersErrorCode,
  },
  partners: {
    status: partnerStatus,
    data: partnerData,
  },
  getPartners,
  getOrders,
  createOrder,
  deleteOrder,
}) => {
  useWebServiceWithBreak(partnerStatus, ordersStatus, getOrders);
  
  useEffect(() => {
    setTimeout(() => {
      getPartners();
      getOrders();
    }, 0);
  }, [getPartners, getOrders]);

  useErrorHandler(ordersStatus, ordersErrorCode, true)

  const deleteOrders = useCallback((id) => {
    deleteOrder(id)
  }, [deleteOrder])

  const downloadNewOrders = useCallback((rowsPerPage, page, rowsPerPageOld, pageOld, orders) => {
    getOrders(rowsPerPage, page, rowsPerPageOld, pageOld, orders)
  }, [getOrders])

  const handleSave = useCallback((selectedCompany, date_from, date_to) => {
    createOrder(selectedCompany, date_from, date_to)
  }, [createOrder])

  return (
    <>
      <TopTitle title={`${useLocalized('nav_orders')}`} subtitle={`${useLocalized('orders_subtitle')}`} />
      {ordersStatus === SUCCESS && <OrderPopup partnersData={partnerData} action={handleSave} />}
      {ordersStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
      {ordersStatus === SUCCESS && <OrderSelector orders={ordersData} action={deleteOrders} downloadAction={downloadNewOrders} />}
      {ordersStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
    </>
  );
};

Orders.propTypes = {
  orders: PropTypes.object,
  getOrders: PropTypes.func,
  getPartners: PropTypes.func,
};

export default withRouter(connect(({
  orders,
  partners
}) => ({
  partners,
  orders
}), {
  getOrders,
  deleteOrder,
  createOrder,
  getPartners,
})(Orders));

