import DriverService from '../../Services/DriverService';

import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  DRIVER_UPDATE_LOADING,
  DRIVER_UPDATE_SUCCESS,
  DRIVER_UPDATE_FAILED,
  DRIVER_DATA_LOADING,
  DRIVER_DATA_FAILED,
  DRIVER_DATA_SUCCESS,
  NOTE_LOADING,
  NOTE_SUCCESS,
  NOTE_FAILED,
  DRIVER_HOLIDAY_SUCCESS,
  DRIVER_HOLIDAY_FAILED,
  CHANGE_DRIVER_DETAILS,
  DRIVER_HISTORIES_LOADING,
  DRIVER_HISTORIES_SUCCESS,
  DRIVER_HISTORIES_FAILED,
  DRIVER_HISTORIES_DELETE_LOADING,
  DRIVER_HISTORIES_DELETE_SUCCESS,
  DRIVER_HISTORIES_DELETE_FAILED,
  DRIVER_DETAIL_RELOAD
} from '../../Redux/ActionTypes';

const initialState = {
  status: DEFAULT,
  errorCode: '',
  driver: {
    id: '', 
    name: '',
    grade: '',
    gradeInitialValue: '',
    hourly_rate_van: '',
    overtime_hourly_rate_van: '',
    hourly_rate_hiab: '',
    overtime_hourly_rate_hiab: '',
    hourly_rate_van_initial_value: '',
    overtime_hourly_rate_van_initial_value: '',
    hourly_rate_hiab_initial_value: '',
    overtime_hourly_rate_hiab_initial_value: '',
    hourly_rate_special: '',
    overtime_hourly_rate_special: '',
    hourly_rate_special_initial_value: '',
    phone: '',
    phoneInitialValue: '',
    address: '',
    addressInitialValue: '',
    color_code: '',
    colorCodeInitialValue: '',
    number: '',
    notes: '',
    histories: '',
    has_special_hourly_rate: false,
  },
}

const driverDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DRIVER_UPDATE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_UPDATE_SUCCESS:
      return {
        ...state,
        status: DRIVER_UPDATE_SUCCESS,
        driver: {
          ...state.driver,
          gradeInitialValue: state.driver.grade,
          phoneInitialValue: state.driver.phone,
          hourly_rate_van_initial_value: state.driver.hourly_rate_van,
          overtime_hourly_rate_van_initial_value: state.driver.overtime_hourly_rate_van,
          hourly_rate_hiab_initial_value: state.driver.hourly_rate_hiab,
          overtime_hourly_rate_hiab_initial_value: state.driver.overtime_hourly_rate_hiab,
          hourly_rate_special_initial_value: state.driver.hourly_rate_special,
          overtime_hourly_rate_special_initial_value: state.driver.overtime_hourly_rate_special,
          colorCodeInitialValue: state.driver.color_code,
          addressInitialValue: state.driver.address,
          numberInitialValue: state.driver.number,
          has_special_hourly_rate: state.driver.has_special_hourly_rate
        }
      };
    case DRIVER_UPDATE_FAILED:
      return {
        ...state,
        status: DRIVER_UPDATE_FAILED,
        errorCode: payload.err,
      };
    case DRIVER_DATA_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_DATA_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        driver: DriverService.getUpdatedDriver(state.driver, payload),
      };
    case DRIVER_DATA_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case NOTE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case NOTE_SUCCESS:
      return {
        ...state,
        status: NOTE_SUCCESS,
        driver: {
          ...state.driver,
          notes: [
            payload,
            ...state.driver.notes,
          ],
        },
      };
    case NOTE_FAILED:
      return {
        ...state,
        status: NOTE_FAILED,
        errorCode: payload.err,
      };
    
    case DRIVER_HOLIDAY_SUCCESS:
      return {
        ...state,
        status: DRIVER_HOLIDAY_SUCCESS,
        driver: {
          ...state.driver,
          histories: [
            payload,
            ...state.driver.histories,
          ],
        },
      };

    case DRIVER_HOLIDAY_FAILED:
      return {
        ...state,
        status: DRIVER_HOLIDAY_FAILED,
        errorCode: payload.err,
      };
    
    case CHANGE_DRIVER_DETAILS:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payload,
        },
      };

    case DRIVER_HISTORIES_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_HISTORIES_SUCCESS:
      return {
        ...state,
        driver: {
          ...state.driver,
          histories: [
            ...payload,
          ],
        },
      };
    
    case DRIVER_HISTORIES_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };

    case DRIVER_HISTORIES_DELETE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_HISTORIES_DELETE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        driver: {
          ...state.driver,
          histories: state.driver.histories.filter(item => item.id !== payload.driverHistoryId)
        },
      };
    
    case DRIVER_HISTORIES_DELETE_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case DRIVER_DETAIL_RELOAD:
      return {
        ...state,
        status: DEFAULT,
      };
    default:
      return state;
  }
};

export default driverDataReducer;
