import { useEffect } from 'react';
import { DEFAULT, SUCCESS } from '../Redux/ActionTypes';

export const useWebService = (status, webService) => {
  useEffect(() => {
    if (status === DEFAULT) {
      setTimeout(() => {
        webService();
      }, 0);
    }
  }, [status, webService]);
};

export const useWebServiceWithBreak = (status, pendingStatus, webService) => {
  useEffect(() => {
    if (status === SUCCESS && pendingStatus === DEFAULT) {
      setTimeout(() => {
        webService();
      }, 0);
    }
  }, [pendingStatus, status, webService]);
};

export const useWebServiceWithId = (status, webService, id) => {
  useEffect(() => {
    if (status === DEFAULT) {
      if (!id) {
        return;
      }
      setTimeout(() => {
        webService(id);
      }, 0);
    }
  }, [status, id, webService]);
};

export const useWebServiceWithDate = (status, webService, startDate, endDate) => {
  useEffect(() => {
    if (status === DEFAULT) {
      setTimeout(() => {
        webService(startDate, endDate);
      }, 0);
    }
  }, [status, startDate, endDate, webService]);
};
