export const ACTIVEBLUE = '#029EFF';
export const LIME_GREEN = '#00E647';
export const DIM_GRAY = '#DBDBDB';
export const DONE_BLUE = '#16BECF';
export const RED = '#E50000';

export const jobTrackingStatusColors = {
    Finished: '029EFF',
    Started: 'FF8A00',
    Sent: '707070',
    Signed: '07CF00',
}
