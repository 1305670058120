import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import DateService from '../../Services/DateService';
import CloseIcon from '@material-ui/icons/Close';
import { deleteDriverHistory } from '../../Redux/Actions/DriverDataAction';
import useLocalized from '../../Data/Localization';
import FormHelperText from '@material-ui/core/FormHelperText';

const DriverHistoryList = ({
  histories,
  deleteDriverHistory,
}) => {

  const driverHolidaySaved = useLocalized('driver_holiday_deleted_successfully');
  const [showSuccessDataHolidayMessage, setSuccessHolidayDeletedMessage] = useState(' ');

  const deleteHoliday = useCallback((historyId) => {

    deleteDriverHistory(historyId);

    setTimeout(() => setSuccessHolidayDeletedMessage(' '), 5000);
    setSuccessHolidayDeletedMessage(driverHolidaySaved);

  }, [deleteDriverHistory, driverHolidaySaved]);

  return (
    <div>
      <FormHelperText component="div"><div className="saved-successfully-data-text deleted-holiday-status">{showSuccessDataHolidayMessage}</div></FormHelperText>
      <div className="history-notes-section">
        {histories.map((hlist) => {
          return (
            <div className="driver-notes-list" key={hlist.id}>
              <div className="note-date">{DateService.getSplitDate(hlist.date)['year']}/W{DateService.getCurrentWeekNumber(hlist.date)}</div>
              <div className="note-content">
                <div className="note-list-grid">
                  <div className="history-title">{hlist.event_type}</div>
                  <div>{hlist.description}</div>
                </div>
                {hlist.event_type === 'DAY OFF' && <div className="delete-history-note"><CloseIcon onClick={() => deleteHoliday(hlist.id)} /></div>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    drivers: { status },
  } = state;
  return { status };
};

export default connect(mapStateToProps, { deleteDriverHistory })(DriverHistoryList);
