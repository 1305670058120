import React from 'react';
import useLocalized from '../../Data/Localization';

function ErrorMessage() {
  return (
      <div className="primary-title">{useLocalized('somethingHappened')}</div>
  );
}

export default ErrorMessage;
