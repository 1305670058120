import React from 'react';
import useLocalized from '../../Data/Localization';

const SearchInput = ({ handleSearch }) => {
    const searchPlaceholder = useLocalized('searchInputPlaceholder');

  return (
    <div className='search'>
        <input
            type="text"
            className='search-input'
            size="medium"
            placeholder={searchPlaceholder}
            onKeyPress={handleSearch}
        />
    </div>
  )
}

export default SearchInput
