import React from 'react';
import PropTypes from 'prop-types';

const TopTitle = ({
  title,
  subtitle,
}) => (
  <div className="dashboard-title">
    <div className="primary-title">{title}</div>
    <div className="secondary-title">{subtitle}</div>
  </div>
);

TopTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TopTitle;
