import WebService from '../../Services/WebServices';
import {
  WEEKLY_RECORD_RELOAD,
  WEEKLY_RECORD_LOADING,
  WEEKLY_RECORD_SUCCESS,
  WEEKLY_RECORD_FAILED,
  WEEKLY_RECORD_DOWNLOAD_FAILED,
  WEEKLY_RECORD_DOWNLOAD_LOADING,
  WEEKLY_RECORD_DOWNLOAD_SUCCESS,
  WEEKLY_RECORD_EMAIL_SEND_FAILED,
  WEEKLY_RECORD_EMAIL_SEND_SUCCESS,
  WEEKLY_RECORD_EMAIL_SEND_LOADING
} from '../ActionTypes';
import { WEEKLY_RECORD, WEEKLY_RECORD_PDF, PDF, REQUEST_ID } from '../../Constants/options';
import { API } from '../../Constants/options';
import { BASE_URL } from '../../Constants/config';

export const getWeeklyRecord = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${WEEKLY_RECORD}?date_from=${dateFrom}&date_to=${dateTo}`,
    };
    const weeklyRecord = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type:  WEEKLY_RECORD_SUCCESS,
      payload: weeklyRecord

    });
  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const createPdf = (dateFrom, dateTo, partnerIds) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_DOWNLOAD_LOADING });
  try {
    let optionsPost = {
      method: 'POST',
      path: `${WEEKLY_RECORD_PDF}${PDF}date_from=${dateFrom}&date_to=${dateTo}`,
      data: {partner_ids: partnerIds}
    };
    const request_id = await WebService.getSharedInstance().callWebservice(
      optionsPost,
    );

    fetch(`${BASE_URL}${API}${WEEKLY_RECORD_PDF}${PDF}${REQUEST_ID}${request_id.request_id}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Token ${WebService.getSharedInstance().token}`
        })
    })
    .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        if (Object.values(partnerIds).length > 1) {
            a.download = `Timesheets_${dateFrom}"_"${dateTo}.zip`;
        } else {
            a.download = `Timesheet_${dateFrom}"_"${dateTo}.pdf`;
        }
        document.body.appendChild(a);
        a.click();
        a.remove(); 
    });

    dispatch({
      type:  WEEKLY_RECORD_DOWNLOAD_SUCCESS,
    });
    
  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_DOWNLOAD_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const sendWeeklyRecordPDFEmail = (dateFrom, dateTo, partnerId) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_EMAIL_SEND_LOADING });
  try {
    let optionsPost = {
      method: 'POST',
      path: `${WEEKLY_RECORD}/email/${partnerId}?date_from=${dateFrom}&date_to=${dateTo}`,
    };

    await WebService.getSharedInstance().callWebservice(
      optionsPost,
    );

    dispatch({
      type:  WEEKLY_RECORD_EMAIL_SEND_SUCCESS,
    });
    
  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_EMAIL_SEND_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const setStateToDefault = () => async (dispatch) => {
  dispatch({
    type: WEEKLY_RECORD_RELOAD
  });
};
