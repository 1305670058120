import { unionBy } from 'lodash';


class DriverService {

    static getUpdatedDrivers = (drivers, payload) => {
        const { id: idToFind, object } = payload;
        let driverToUpdate = drivers.find(({ id }) => id === idToFind);
        if (!driverToUpdate) {
          return drivers;
        }
        driverToUpdate = {
          ...driverToUpdate,
          ...object,
        };
        return unionBy([driverToUpdate], drivers, 'id');
      };

    static getUpdatedDriver = (driver, payload) => {

      return {
        ...driver,
        number: null,
        ...payload,
        addressInitialValue: payload.address,
        phoneInitialValue: payload.phone,
        hourly_rate_van_initial_value: payload.hourly_rate_van,
        overtime_hourly_rate_van_initial_value: payload.overtime_hourly_rate_van,
        hourly_rate_hiab_initial_value: payload.hourly_rate_hiab,
        overtime_hourly_rate_hiab_initial_value: payload.overtime_hourly_rate_hiab,
        gradeInitialValue: payload.grade,
        colorCodeInitialValue: payload.color_code,
        numberInitialValue: payload.number
      };
  }

}

export default DriverService;
