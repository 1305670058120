import WebService from '../../Services/WebServices';
import {
  SCHEDULES_LOADING,
  SCHEDULES_FAILED,
  SCHEDULES_SUCCESS,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
  CREATE_SCHEDULE_LOADING,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE_STATUS_LOADING,
  UPDATE_SCHEDULE_STATUS_SUCCESS,
  UPDATE_SCHEDULE_STATUS_FAILED,
  SCHEDULE_RELOAD
} from '../../Redux/ActionTypes';
import { SCHEDULES, ORDERS } from '../../Constants/options';

export const getSchedule = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({ type: SCHEDULES_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${SCHEDULES}?date_from=${dateFrom}&date_to=${dateTo}`,
    };
    const schedules = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type: SCHEDULES_SUCCESS,
      payload: {
        scheduleData: schedules
      },
    });
  } catch (err) {
    dispatch({
      type: SCHEDULES_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const deleteSchedule = (scheduleId, driverId) => async (dispatch) => {
  dispatch({ type: DELETE_SCHEDULE_LOADING });
  try {
    let options = {
      method: 'DELETE',
      path: `${SCHEDULES}/${scheduleId}`,
    };
    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: DELETE_SCHEDULE_SUCCESS,
      payload: { scheduleId, driverId }
    });
  } catch (err) {
    dispatch({
      type: DELETE_SCHEDULE_FAILED,
      payload: {
        err,
      }
    });
  }
};


export const createOrUpdateSchedule = ({ value, driverId, date, scheduleId }) => async (dispatch) => {
  dispatch({ type: CREATE_SCHEDULE_LOADING });
  try {
    let options = {
      method: 'PUT',
      path: `${SCHEDULES}`,
      data: {
        driver_id: driverId,
        order_id: value,
        date,
        schedule_id: scheduleId
      }
    };

    const schedule = await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: CREATE_SCHEDULE_SUCCESS,
      payload: {
        schedule,
        driverId
      }
    });
  } catch (err) {
    dispatch({
      type: CREATE_SCHEDULE_FAILED,
      payload: {
        err,
      }
    });
  }
};

export const updateScheduleStatus = (scheduleId, status, driverId) => async (dispatch) => {
  dispatch({ type: UPDATE_SCHEDULE_STATUS_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${SCHEDULES}`,
      data: [
        {
          schedule_id: scheduleId,
          status,
        }
      ]
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: UPDATE_SCHEDULE_STATUS_SUCCESS,
      payload: {
        scheduleId,
        status,
        driverId
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SCHEDULE_STATUS_FAILED,
      payload: {
        err,
      }
    });
  }
};

export const updateNextDayScheduleStatuses = (scheduleData) => async (dispatch) => {
  dispatch({ type: UPDATE_SCHEDULE_STATUS_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${SCHEDULES}`,
      data: scheduleData
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: SCHEDULE_RELOAD,
    });
  } catch (err) {
    dispatch({ 
      type: UPDATE_SCHEDULE_STATUS_FAILED, 
      payload: {
        err,
      }
    });
  }
};

export const getAvailableOrdersByDateSync = async (date) => {
  try {
    let options = {
      method: 'GET',
      path: `${ORDERS}?available=true&date=${date}`,
    };

    const orders = await WebService.getSharedInstance().callWebservice(options);

    return orders

  } catch (err) {
    return err
  }
};

export const setStateToDefault = () => async (dispatch) => {
  dispatch({
    type: SCHEDULE_RELOAD
  });

};
