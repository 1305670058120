import React, { useState, useCallback, useEffect } from 'react';
import useLocalized from '../../Data/Localization';
import { connect } from 'react-redux';
import { SUCCESS, LOADING, FAIL, PDF_SUCCESS, PDF_LOADING, PDF_FAIL } from '../../Redux/ActionTypes';
import { getWeeklyRecord, setStateToDefault, createPdf, sendWeeklyRecordPDFEmail } from '../../Redux/Actions/WeeklyRecordActions';
import TopTitle from '../../Components/TopTitle/TopTitle';
import DateService from '../../Services/DateService';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import WeeklyRecordList from '../../Components/WeeklyRecordList/WeeklyRecordList';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Popover from '@material-ui/core/Popover';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { getPartners } from '../../Redux/Actions/PartnerActions';
import { setPartnerData } from '../../Redux/Actions/PartnerDataAction';

const WeeklyRecord = ({
    weeklyRecord: {
        status: weeklyRecordStatus,
        weeklyRecord,
    },
    partners: {
        status: partnerStatus,
        data: partnerData,
    },
    getWeeklyRecord,
    setStateToDefault,
    createPdf,
    getPartners,
    sendWeeklyRecordPDFEmail,
    setPartnerData
}) => {

    const [currentDate] = useState(new Date());
    const [dateFrom, setStartSelectedDate] = useState(DateService.convertDate(DateService.getWeekStartDate(currentDate)));
    const [dateTo, setEndSelectedDate] = useState(DateService.convertDate(DateService.getWeekEndDate(currentDate)));

    const labelWeekSelect = useLocalized('week_select_label');

    const currentWeek = DateService.getCurrentWeekNumber(DateService.getCurrentDate());

    const [weekNumber, setWeek] = useState(currentWeek);
    const [popUpValues, setPopUpValues] = useState({});

    useEffect(() => {
        setTimeout(() => {
            getWeeklyRecord(dateFrom, dateTo);
            getPartners();
        }, 0);
    }, [dateFrom, dateTo, getWeeklyRecord, getPartners]);

    const [state, setState] = useState({
        hoverRange: undefined,
        selectedDays: [],
    });

    const handleWeekClick = useCallback((weekNumber, days, e) => {
        setWeek(weekNumber);
        setStartSelectedDate(DateService.convertDate(days[0]));
        setEndSelectedDate(DateService.convertDate(days[6]));
        setStateToDefault();
        setState({
            selectedDays: days,
        });
        setAnchorEl(null);
    }, [setStateToDefault]);

    const { hoverRange, selectedDays } = state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'week-select-popover' : undefined;

    const handleDownloadWeek = (dateFrom, dateTo, partnerIds) => {
        createPdf(dateFrom, dateTo, partnerIds);
    }

    const sendEmailAction = (dateFrom, dateTo, partnerId) => {
        sendWeeklyRecordPDFEmail(dateFrom, dateTo, partnerId);
    }

    const savePartnerData = (partner, partnerId) => {
        setPartnerData( partnerId, partner, () => {})
    }

    return (
        <>
            <TopTitle title={`${useLocalized('timesheet_title')}`} subtitle={`${useLocalized('invoice_subtitle')}`} />
            <Grid container spacing={3}>
                <div className="week-select-section">
                    <Grid item xs={12} className="start-end-date-send-button">
                        <div>
                            <InputLabel className="simple-select-label">{labelWeekSelect}</InputLabel>
                            <div className="select-week-input" aria-describedby={id} onClick={handleClick}>
                                {DateService.getSplitDate(dateFrom)['year']}
                                &nbsp;-&nbsp;
                                {DateService.getSplitDate(dateTo)['year']}
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <DayPicker
                                    selectedDays={selectedDays}
                                    showWeekNumbers
                                    showOutsideDays
                                    modifiers={modifiers}
                                    onWeekClick={handleWeekClick}
                                    firstDayOfWeek={1}
                                />
                            </Popover>
                        </div>
                    </Grid>
                </div>
            </Grid>
            {(weeklyRecordStatus === SUCCESS || weeklyRecordStatus === PDF_SUCCESS) && <WeeklyRecordList
                weeklyRecord={weeklyRecord}
                weekNumber={weekNumber}
                dateFrom={dateFrom}
                dateTo={dateTo}
                handleDownloadWeek={handleDownloadWeek}
                emailAction={sendEmailAction}
                partnerContacts={partnerData.partnersData}
                partnerSaveAction={savePartnerData}
            />}
            {(weeklyRecordStatus === LOADING || weeklyRecordStatus === PDF_LOADING) && <div className="loading-icon"><CircularProgress /></div>}
            {(weeklyRecordStatus === FAIL || weeklyRecordStatus === PDF_FAIL) && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
}

WeeklyRecord.propTypes = {
    weeklyRecord: PropTypes.object,
    getWeeklyRecord: PropTypes.func,
    createPdf: PropTypes.func,
    setStateToDefault: PropTypes.func,
};

export default connect(({
    weeklyRecord,
    partners
}) => ({
    weeklyRecord,
    partners
}), {
    getWeeklyRecord,
    createPdf,
    setStateToDefault,
    getPartners,
    sendWeeklyRecordPDFEmail,
    setPartnerData
})(WeeklyRecord);
