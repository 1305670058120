import React from 'react';
import useLocalized from '../../Data/Localization';
import logo from '../../Assets/logo.svg';

function DashboardTitle() {
  return (
    <div className="dashboard-title">
      <img src={logo} />
    </div>
  );
}

export default DashboardTitle;
