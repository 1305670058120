import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useLocalized from '../../Data/Localization';
import { connect } from 'react-redux';
import TopTitle from '../../Components/TopTitle/TopTitle';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { getDrivers } from '../../Redux/Actions/DriverActions';
import { SUCCESS, LOADING, FAIL } from '../../Redux/ActionTypes';
import DriverSelector from '../../Components/DriverSelector/DriversSelector';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';

const Drivers = ({
  drivers: {
    status: driversStatus,
    driverUpdateStatus,
    drivers,
  },
  getDrivers,

}) => {

  useEffect(() => {
    setTimeout(() => {
      getDrivers();
    }, 0);
  }, [getDrivers]);

  return (
    <>
      <TopTitle title={`${useLocalized('drivers_title')}`} subtitle={`${useLocalized('drivers_subtitle')}`} />
      {(driversStatus === LOADING || driverUpdateStatus == LOADING) && <div className="loading-icon"><CircularProgress /></div>}
      {((driversStatus === SUCCESS && driverUpdateStatus != LOADING && driverUpdateStatus != FAIL) || driverUpdateStatus == SUCCESS) && <DriverSelector drivers={drivers} />}
      {(driversStatus === FAIL || driverUpdateStatus == FAIL) &&  <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
    </>
  );
};

Drivers.propTypes = {
  drivers: PropTypes.object,
  getDrivers: PropTypes.func,
};

export default withRouter(connect(({
  drivers, driverDetail
}) => ({
  drivers, driverDetail
}), {
  getDrivers
})(Drivers));
