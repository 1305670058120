import { unionBy } from 'lodash';


class PartnerService {

    static getUpdatedParters = (partners, payload) => {
        const { id: idToFind, object } = payload;
        let partnerToUpdate = partners.find(({ id }) => id === idToFind);
        if (!partnerToUpdate) {
          return partners;
        }
        partnerToUpdate = {
          ...partnerToUpdate,
          ...object,
        };
        return unionBy([partnerToUpdate], partners, 'id');
      };

    static getUpdatedPartner = (partners, payload) => {

      return {
        ...partners,
        number: null,
        ...payload,
      };
  }

}

export default PartnerService;
