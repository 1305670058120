import currentWeekNumber from 'current-week-number';
import { startOfWeek, endOfWeek, format, add } from 'date-fns';

class DateService {

    static getSplitDate(str) {
        let [year, month, day] = str.match(/(\d+)-(\d+)-(\d+)/);
        return { year, month, day };
    }

    static getWeekDay(date) {
        return (date.getDay() + 6) % 7;
    }

    static getCurrentWeekNumber(date) {
        const currentWeek = currentWeekNumber(date);
        return currentWeek;
    }

    static getCurrentDate() {
        return format(new Date(), 'yyyy-MM-dd');
    }

    static getNextDay() {
        return add(new Date(), { days: 1 })
    }

    static convertDate(date) {
        return format(date, 'yyyy-MM-dd');
    }

    static getNextWeek() {
        let today = new Date()
        const currentWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        return currentWeek;
    }

    static getWeekStartDate(date) {
        const start = startOfWeek(date, { weekStartsOn: 1 });
        return start;
    };

    static getWeekEndDate(date) {
        const end = endOfWeek(date, { weekStartsOn: 1 });
        return end
    };

    static checkIfDateIsInPast(date) {
        let today = new Date()
        return new Date(date) < new Date(today.toDateString())

    };

    static getDayName(dateString) {
        const [date, options] = [new Date(dateString), { weekday: 'long' }];
        return new Intl.DateTimeFormat('en-Us', options).format(date);
    }

    static getBritishTime(date, hour) {
        var finalhour = hour.split(":");

        var dateObj = new Date(date)
        var utcParsedDate = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDay(), finalhour[0], finalhour[1], 0))
        var final = utcParsedDate.toLocaleString('en-GB', { timeZone: 'Europe/London' })

        var changedFinalHour = final.split(",");
        var splitHour = changedFinalHour[1].split(":");
        return splitHour[0] + ':' + splitHour[1];
    };
}

export default DateService;
