import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { loginAction } from '../../Redux/Actions/LoginAction';
import { useHistory } from 'react-router-dom';
import useLocalized from '../../Data/Localization';
import Copyright from '../../Components/Copyright/Copyright';
import { useErrorHandler } from '../../CustomHooks';
import { Alert } from '@material-ui/lab';
import { FAIL } from '../../Redux/ActionTypes';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const Login = ({ loginAction, token, loginStatus, errorCode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const errorMessage = useErrorHandler(loginStatus, errorCode, false)

  const handleClick = useCallback(() => {
    loginAction(email, password);
  }, [email, loginAction, password]);

  useEffect(() => {
    if (token !== '') {
      history.replace('/drivers');
    }
  }, [history, token]);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleClick();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleClick]);

  return (
    <div className="login-screen">
      <div className="title-container">
        <Typography variant="h6" className="bigTitle">
          {useLocalized('log_title')}
        </Typography>
      </div>
      <div className="login-form-box">
        <Typography component="h1" variant="h5" className="title">
          {useLocalized('log_subtitle')}
        </Typography>
        <TextField
          error={loginStatus === FAIL ? true : false}
          className="email"
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={useLocalized('log_email_placeholder')}
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          error={loginStatus === FAIL ? true : false}
          className="password"
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label={useLocalized('log_password_placeholder')}
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => { showPassword ? setShowPassword(false) : setShowPassword(true) }}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {loginStatus === FAIL ? (
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        ) : null}
        <Button
          fullWidth
          variant="contained"
          className="button"
          onClick={handleClick}
        >
          {useLocalized('log_buttontitle')}
        </Button>
      </div>
      <Copyright />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    user: { loginStatus, token, errorCode },
  } = state.user;
  return { loginStatus, token, errorCode };
};

export default connect(mapStateToProps, { loginAction })(Login);
