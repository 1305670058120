import useLocalized from '../../Data/Localization';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import DateService from '../../Services/DateService';
import DriverCalendar from '../../Components/DriverCalendar/DriverCalendar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        width: 420
    },
    sccont: {
        marginTop: 80,
    },
}));

const DriverSchedule = ({
    schedules,
    dateFrom,
    weekNumber,
    handleDeleteSchedule,
    createOrUpdateSchedule,
    updateScheduleStatus
}) => {

    const classes = useStyles();
    const labelNr = useLocalized('drivers_table_head_cell_nr');
    const labelDriverName = useLocalized('drivers_table_head_cell_driver_name');
    const days = ['M', 'Tu', 'W', 'Th', 'Fr', 'Sa', 'Su'];

    const checkIsHoliday = (date, holidays) => {
        return holidays.find(element => (date <= new Date(element.to_date) && new Date(element.from_date) <= date)) != null
    };

    const getWeekDays = (driverData) => {
        const driverCalendar = [];
        let date = new Date(dateFrom)
        for (let day = 0; day < 7; day++) {
            let newDate = DateService.convertDate(date)
            let isHoliday = checkIsHoliday(date, driverData.driver_holidays)
            driverCalendar.push(<DriverCalendar
                key={days[day]}
                day={days[day]}
                date={newDate}
                driverId={driverData.driver_details.driver_id}
                handleDeleteSchedule={handleDeleteSchedule}
                createOrUpdateSchedule={createOrUpdateSchedule}
                updateScheduleStatus={updateScheduleStatus}
                driverHoliday={isHoliday}
            />);  
            date = new Date(date.setDate(date.getDate() + 1))
        }
        driverData.driver_schedules.forEach((driverschedule) => {

            const itemDate = new Date(driverschedule.date);
            const dayNumber = DateService.getWeekDay(itemDate); 
            driverCalendar[dayNumber] = (
                    <DriverCalendar
                        key={days[dayNumber]}
                        day={days[dayNumber]}
                        date={driverschedule.date}
                        scheduleId={driverschedule.id}
                        driverId={driverData.driver_details.driver_id}
                        orderId={driverschedule.order_id}
                        companyName={driverschedule.order_company}
                        companyAddress={driverschedule.order_location}
                        status={driverschedule.status}
                        handleDeleteSchedule={handleDeleteSchedule}
                        createOrUpdateSchedule={createOrUpdateSchedule}
                        updateScheduleStatus={updateScheduleStatus}
                        driverHoliday={false}
                    />
                );
        });
        return driverCalendar;
    };

    return (
        <>
            <Grid container className={classes.root} spacing={2}>
                <Grid container className={classes.sccont} spacing={0}>
                    <div className="scrolling-wrapper-flexbox">
                        {schedules.scheduleData.map((value, nrdriver) => (
                            <Grid className="driver-schedule-list" xs={false} key={nrdriver} item>
                                <Paper className={classes.paper}>
                                    <div className="driver-schedule-header">
                                        <label className="labelCustom">{labelNr}</label>
                                        <div className="driver-info-section">
                                            <div className="driver-info">
                                                <div className="driver-color-row" style={{ backgroundColor: '#' + value.driver_details.driver_color_code }}></div>
                                                <div className="driver-nr">#{value.driver_details.driver_number}</div>
                                            </div>
                                            <div className="driver-location">{value.driver_details.driver_location}</div>
                                        </div>
                                        <div className="driver-name-row">
                                            <label className="labelCustom">{labelDriverName}</label>
                                            <div className="driver-name">{value.driver_details.driver_name}</div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="week-row-to-schedule">
                                        <div className="week-nr-col">
                                            {nrdriver === 0 && <div className="note-date">W{weekNumber}</div>}
                                            {nrdriver > 0 && <div className="note-date">&nbsp;</div>}
                                        </div>
                                        <div className="driver-week-list">
                                            {getWeekDays(value)}
                                        </div>
                                        <div className="clr"></div>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

DriverSchedule.propTypes = {
    schedules: PropTypes.object,
    dateFrom: PropTypes.string,
    weekNumber: PropTypes.number,
    handleDeleteSchedule: PropTypes.func,
    createOrUpdateSchedule: PropTypes.func,
    updateScheduleStatus: PropTypes.func
};

export default DriverSchedule;
