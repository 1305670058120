export const PartnerListColumnsTypes = {
    NR: 'nr',
    COMPANY_NAME: 'companyName',
    LOCATION: 'location',
    PARTNER_SINCE: 'partnerSince',
    HOURLY_RATE_VAN: 'hourlyRateVan',
    OVERTIME_HOURLY_RATE_VAN: 'overtimeHourlyRateVan',
    HOURLY_RATE_VAN_PAYROLL: 'hourlyRateVanPayroll',
    OVERTIME_HOURLY_RATE_VAN_PAYROLL: 'overtimeHourlyRateVanPayroll',
    HOURLY_RATE_HIAB: 'hourlyRateHiab',
    OVERTIME_HOURLY_RATE_HIAB: 'overtimeHourlyRateHiab',
    HOURLY_RATE_HIAB_PAYROLL: 'hourlyRateHiabPayroll',
    OVERTIME_HOURLY_RATE_HIAB_PAYROLL: 'overtimeHourlyRateHiabPayroll',
    HOURLY_RATE_SEVEN_TONS: 'hourlyRateSevenTons',
    OVERTIME_HOURLY_RATE_SEVEN_TONS: 'overtimeHourlyRateSevenTons',
    HOURLY_RATE_LOFT_L: 'hourlyRateLoftL',
    OVERTIME_HOURLY_RATE_LOFT_L: 'overtimeHourlyRateLoftL',
    HOURLY_RATE_SPECIAL: 'hourlyRateSpecial',
    OVERTIME_HOURLY_RATE_SPECIAL: 'overtimeHourlyRateSpecial',
  }
