import React from 'react';
import DateService from '../../Services/DateService';

function OldOrderTableCellBody({ column, value, i, action }) {
  switch (column.id) {
    case 'nr':
      return (
        <div>#{i + 1}</div>
      )
    case 'location':
      if (value !== null && value !== '-') {
        return (
          <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
        )
      } else {
        return (
          <div className="text">-</div>
        )
      }
    case 'start_date':
      return (
        <div className="text">{DateService.getSplitDate(value)['year']}</div>
      )
    case 'end_date':
      return (
        <div className="text">{DateService.getSplitDate(value)['year']}</div>
      )
    case '-':
      return (
        <div className="text">-</div>
      )
    default:
      return (
        <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
      )
  }
}

export default OldOrderTableCellBody;
