import { unionBy } from 'lodash';
import { scheduleStatuses } from '../Constants';
import DateService from '../Services/DateService';


class ScheduleService {

    static deleteSchedule = (scheduleData, payload) => {
      const { scheduleId, driverId } = payload;

      let scheduleToUpdate = scheduleData.find(o => o.driver_details.driver_id === driverId);
      if (!scheduleToUpdate) {
        return scheduleData;
      }
      scheduleToUpdate.driver_schedules = scheduleToUpdate.driver_schedules.filter(o => o.id !== scheduleId)

      return scheduleData
  
  }

  static addSchedule = (scheduleData, payload) => {
    const { schedule, driverId } = payload;

    let scheduleToUpdate = scheduleData.find(o => o.driver_details.driver_id === driverId);
    if (!scheduleToUpdate) {
      return scheduleData;
    }

    const mergedDriverSchedules = unionBy([schedule], [...scheduleToUpdate.driver_schedules], 'id');

    scheduleToUpdate = {
      ...scheduleToUpdate,
      driver_schedules: mergedDriverSchedules,
    }

    const mergedSchedules = unionBy([scheduleToUpdate], scheduleData, 'driver_details.driver_id');
    const sortedSchedules = this.getOrderedSchedules(mergedSchedules);

    return sortedSchedules;
  }

  static updateScheduleStatus = (scheduleData, payload) => {
    const { scheduleId, status, driverId } = payload;

    let scheduleToUpdate = scheduleData.find(o => o.driver_details.driver_id === driverId);
    if (!scheduleToUpdate) {
      return scheduleData;
    }

    let scheduleElementToUpdate = scheduleToUpdate.driver_schedules.find(o => o.id === scheduleId);

    scheduleElementToUpdate.status = status

    return scheduleData
  }


  static getNextDaySchedules = (scheduleData) => {
    const tomorrow = DateService.convertDate(DateService.getNextDay())

    let nextDaySchedules = []
    scheduleData.scheduleData.forEach( (schedule) => {
      let scheduleToSend = schedule.driver_schedules.filter(o => (o.date === tomorrow && o.status === scheduleStatuses.PLANNED))
      scheduleToSend.forEach((nexDaySchedule) => nextDaySchedules.push({
        schedule_id: nexDaySchedule.id,
        status: scheduleStatuses.SENT
      }))
    })
    return nextDaySchedules
  }

  static getOrderedSchedules = (schedules) => {
    return schedules.sort(({ driver_details: { driver_number: a } }, { driver_details: { driver_number: b } }) => {
      if (a === null || a === undefined) {
        return 1;
      }
      if (b === null || b === undefined) {
        return -1;
      }
      if (a < b) {
        return -1;
      }
      return 1;
    });
  }

}

export default ScheduleService;
