import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function PrivateRoute({component: Component, token, ...rest}) {
  return (
    <Route
      {...rest}
      render={() => token !== ''
        ? <Component />
        : <Redirect to='/login' />}
    />
  )
}

const mapStateToProps = (state) => {
  const {
    user: { token },
  } = state.user;
  return { token };
};

export default connect(mapStateToProps, null)(PrivateRoute);
