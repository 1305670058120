import { connect } from 'react-redux';
import { gradeColors } from '../../Constants/options';
import { Link } from 'react-router-dom';
import { getDrivers, setStateToDefault, updateDriverStatus, deleteDriver } from '../../Redux/Actions/DriverActions';
import DateService from '../../Services/DateService'; 
import TrashCan from '../../Assets/trashCan.svg';
import AddUserIcon from '../../Assets/addUserIcon.svg';

function PendingDriverTableCellBody({ column, value, details, remove, updateDriverStatus, setStateToDefault, getDrivers, deleteDriver }) {

  if (column.id === 'number') {
    return (
      <div>#{value}</div>
    )
  } else if (column.id === 'grade') {
    return (
      <div className="grade-circle-cell"><span style={{ backgroundColor: gradeColors[value] }} className="grade-circle"></span></div>
    )
  } else if (column.id === 'colorCode') {
    if (value !== null && value !== '-') {
      const colorvalue = value;
      return (
        <div className="color-line" style={{ backgroundColor: '#' + colorvalue }}></div>
      )
    } else {
      const colorvalue = '707070';
      return (
        <div className="color-line" style={{ backgroundColor: '#' + colorvalue }}></div>
      )
    }
  } else if (column.id === 'location') {
    if (value !== null && value !== '-') {
      return (
        <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
      )
    } else {
      const defaultlocationtext = '-';
      return (
        <div className="text">{defaultlocationtext}</div>
      )
    }
  } else if (column.id === 'category') {
    if (value !== null && value !== '-') {
      return (
        <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
      )
    } else {
      const defaultcategory = 1;
      return (
        <div className="text">{defaultcategory}</div>
      )
    }
  } else if (column.id === 'id') {
    return (
      <div className="driver-operations">
        <img className='button' alt='AddUserIcon' src={AddUserIcon} onClick={() => updateDriverStatus(value, {status: 'Active'}, setTimeout(() => { getDrivers() }, 0), DateService.getCurrentDate())} />
        <img className='button' alt='TrashCan' src={TrashCan} onClick={() =>  deleteDriver(value)} />
      </div>
    )
  } else {
    return (
      <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    drivers: { status },
  } = state;
  return { status };
};

export default connect(mapStateToProps, { deleteDriver, updateDriverStatus, setStateToDefault, getDrivers })(PendingDriverTableCellBody);
