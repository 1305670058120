import React, { useCallback } from 'react';
import DateService from '../../Services/DateService';
import CellTextField from '../CellTextField/CellTextField';
import { PartnerListColumnsTypes } from '../../Constants/partnerListColumnTypes';

function PartnerListCell({
  column,
  value,
  j,
  onChange,
}) {

  const handleEdit = useCallback((vehicleType, j, newValue) => {
    onChange(vehicleType, j, newValue)
  }, [onChange])

  switch (column.id) {
    case PartnerListColumnsTypes.NR:
      return (
        <div>#{j + 1}</div>
      )
    case PartnerListColumnsTypes.LOCATION:
      if (value !== null && value !== '-') {
        return (
          <div className="partnerListCellText">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
        )
      } else {
        return (
          <div className="partnerListCellText">{'-'}</div>
        )
      }
    case PartnerListColumnsTypes.PARTNER_SINCE:
      return (
        <div className="partnerListCellText">{DateService.getSplitDate(value)['year']}</div>
      )
    case PartnerListColumnsTypes.HOURLY_RATE_VAN:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_VAN} />
    case PartnerListColumnsTypes.HOURLY_RATE_HIAB:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_HIAB} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB} />
    case PartnerListColumnsTypes.HOURLY_RATE_SPECIAL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_SPECIAL} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SPECIAL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SPECIAL} />
    case PartnerListColumnsTypes.HOURLY_RATE_VAN_PAYROLL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_VAN_PAYROLL} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN_PAYROLL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN_PAYROLL} />
    case PartnerListColumnsTypes.HOURLY_RATE_HIAB_PAYROLL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_HIAB_PAYROLL} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB_PAYROLL:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB_PAYROLL} />
    case PartnerListColumnsTypes.HOURLY_RATE_SEVEN_TONS:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_SEVEN_TONS} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SEVEN_TONS:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SEVEN_TONS} />
    case PartnerListColumnsTypes.HOURLY_RATE_LOFT_L:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.HOURLY_RATE_LOFT_L} />
    case PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_LOFT_L:
      return <CellTextField value={value} j={j} handleEdit={handleEdit} vehicleType={PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_LOFT_L} />
    default:
      return (
        <div className="partnerListCellText">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
      )
  }
}

export default PartnerListCell;
