import { useContext } from 'react';
import LocalizationService, {
  LocalizedContext,
} from '../Services/LocalizationService';

const useLocalizedErrorMessage = (errorCode) => {
  const locale = useContext(LocalizedContext);
  return LocalizationService.getLocalizedErrorMessage(locale, errorCode);
};

export default useLocalizedErrorMessage;
