import WebService from '../../Services/WebServices';
import DateService from '../../Services/DateService';

import {
  DRIVER_DATA_LOADING,
  DRIVER_DATA_FAILED,
  DRIVER_DATA_SUCCESS,
  DRIVER_UPDATE_LOADING,
  DRIVER_UPDATE_FAILED,
  DRIVER_UPDATE_SUCCESS,
  NOTE_LOADING,
  NOTE_SUCCESS,
  NOTE_FAILED,
  DRIVER_HOLIDAY_SUCCESS,
  DRIVER_HOLIDAY_FAILED,
  CHANGE_DRIVER_DETAILS,
  DRIVER_HISTORIES_LOADING,
  DRIVER_HISTORIES_SUCCESS,
  DRIVER_HISTORIES_FAILED,
  DRIVER_HISTORIES_DELETE_LOADING,
  DRIVER_HISTORIES_DELETE_SUCCESS,
  DRIVER_HISTORIES_DELETE_FAILED,
} from '../../Redux/ActionTypes';
import { DRIVERS, DRIVER_PROFILE, DRIVER_NOTES, DRIVER_HISTORY, DRIVER_HOLIDAYS, DRIVER_HISTORIES } from '../../Constants/options';

export const getDriverById = (driverId) => async (dispatch) => {
  dispatch({ type: DRIVER_DATA_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${DRIVERS}/${driverId}${DRIVER_PROFILE}`,
    };

    const driverData = await WebService.getSharedInstance().callWebservice(
      options,
    );
    dispatch({
      type: DRIVER_DATA_SUCCESS,
      payload: driverData
    });

  } catch (err) {
    dispatch({
      type: DRIVER_DATA_FAILED,
      payload: {
        err
      }
    });
  }
};



export const setDriverData = (
  driverId,
  driver,
  success,
) => async (dispatch) => {

  dispatch({ type: DRIVER_UPDATE_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${DRIVERS}/${driverId}`,
      data: driver
    };

    await WebService.getSharedInstance().callWebservice(options);

    success();


    dispatch({
      type: DRIVER_UPDATE_SUCCESS,
    });

  } catch (err) {
    dispatch({
      type: DRIVER_UPDATE_FAILED,
      payload: {
        err
      },
    });
  }
};

export const getDriverHistories = (driverId) => async (dispatch) => {
  dispatch({ type: DRIVER_HISTORIES_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${DRIVERS}/${driverId}${DRIVER_HISTORIES}`,
    };

    const driverHistories = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type: DRIVER_HISTORIES_SUCCESS,
      payload: driverHistories
    });

  } catch (err) {
    dispatch({
      type: DRIVER_HISTORIES_FAILED,
      payload: {
        err
      }
    });
  }
};

export const setDriverDataNote = (driverId, description) => async (
  dispatch,
) => {
  dispatch({ type: NOTE_LOADING });
  try {
    const currentDate = DateService.getCurrentDate();

    let options = {
      method: 'POST',
      path: `${DRIVERS}/${driverId}${DRIVER_NOTES}`,
      data: { description, date: currentDate },
    };

    const driverNote = await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: NOTE_SUCCESS,
      payload: driverNote
    });
  } catch (err) {
    dispatch({
      type: NOTE_FAILED,
      payload: {
        err
      },
    });
  }
};

export const deleteDriverHistory = (driverHistoryId) => async (
  dispatch,
) => {

  dispatch({ type: DRIVER_HISTORIES_DELETE_LOADING });
  try {
    let options = {
      method: 'DELETE',
      path: `${DRIVER_HISTORY}/${driverHistoryId}`,
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: DRIVER_HISTORIES_DELETE_SUCCESS,
      payload: {
        driverHistoryId
      }
    });

  } catch (err) {
    dispatch({
      type: DRIVER_HISTORIES_DELETE_FAILED,
      payload: {
        err
      },
    });
  }
};

export const setDriverHoliday = (driverId, date_from, date_to, successCallback) => async (
  dispatch,
) => {
  try {
    let options = {
      method: 'POST',
      path: `${DRIVERS}/${driverId}${DRIVER_HOLIDAYS}`,
      data: { date_from, date_to },
    };

    const driverHistory = await WebService.getSharedInstance().callWebservice(options);
    dispatch({
      type: DRIVER_HOLIDAY_SUCCESS,
      payload: driverHistory
      
    });
    successCallback();
  } catch (err) {
    dispatch({
      type: DRIVER_HOLIDAY_FAILED,
      payload: {
        err
      },
    });
  }
};

export const changeDriverDetails = (driver) => (dispatch) => {
  dispatch({ 
    type: CHANGE_DRIVER_DETAILS,
    payload: driver
  });

};