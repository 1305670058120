import { useContext } from 'react';
import LocalizationService, {
  LocalizedContext,
} from '../Services/LocalizationService';

const useLocalized = (text) => {
  const locale = useContext(LocalizedContext);
  return LocalizationService.getLocalizedText(locale, text);
};

export default useLocalized;
