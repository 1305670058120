//WebService constants
export const API = '/api';
export const LOGIN = '/token';
export const ADMIN_SCOPE = 'admin';
export const DRIVERS = '/drivers';
export const DRIVER_PROFILE = '/profile';
export const DRIVER_NOTES = '/notes';
export const DRIVER_HISTORY = '/driverhistories';
export const DRIVER_HOLIDAYS = '/holidays';
export const DRIVER_HISTORIES = '/histories';
export const PARTNERS = '/partners';
export const ORDERS = '/orders'
export const CURRENCY_SYMBOL = '£';
export const SCHEDULES = '/schedules';
export const TIMESHEETS = '/timesheets';
export const JOBS = '/jobs';
export const INVOICES = '/invoice';
export const JOBS_DETAILS = '/details';
export const WEEKLY_RECORD = '/weekly-record';
export const WEEKLY_RECORD_PDF = '/weekly_record';
export const PDF = '/pdf?';
export const REQUEST_ID = 'request_id=';
export const LOCATIONS = '/locations';

//color constants
export const SPLITTER_COLOR = '#B8B8B8';
export const gradeColors = {
    MIDDLE: '#FFD500',
    PRO: '#07CF00',
    BEGINNER: '#E50000',
    null: '#FFD500',
};
export const orderStatusColors = {
    ASSIGNED: '#07CF00',
    NOT_ASSIGNED: '#E50000',
}

export const FORMAT_DATE = 'dd.MM.yyyy';
