import React, { useState, useCallback, useEffect } from 'react';
import useLocalized from '../../Data/Localization';
import { LOADING, FAIL } from '../../Redux/ActionTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TopTitle from '../../Components/TopTitle/TopTitle';
import { useWebServiceWithDate, useErrorHandler } from '../../CustomHooks';
import { getTimesheet, setStateToDefault, setDriverJobData, getTimesheetByDriver } from '../../Redux/Actions/TimesheetActions';
import DriversTimesheet from '../../Components/DriversTimesheet/DriversTimesheet';
import SearchInput from '../../Components/SearchInput/SearchInput';
import DateService from '../../Services/DateService';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Popover from '@material-ui/core/Popover';
import InputLabel from '@material-ui/core/InputLabel';

const Timesheets = ({
    timesheets: {
        status: timesheetStatus,
        errorCode,
        data: timesheetData,
    },
    getTimesheet,
    setStateToDefault,
    setDriverJobData, 
    getTimesheetByDriver
}) => {

    const [filteredTimesheetData, setFilteredTimesheetData] = useState({ scheduleData: [] });
    const [searchedForText, setSearchedForText] = useState(false);

    const [currentDate] = useState(new Date());
    const [dateFrom, setStartSelectedDate] = useState(DateService.convertDate(DateService.getWeekStartDate(currentDate)));
    const [dateTo, setEndSelectedDate] = useState(DateService.convertDate(DateService.getWeekEndDate(currentDate)));

    const labelWeekSelect = useLocalized('week_select_label');
    const searchErrorMsg = useLocalized('searchErrorMessage');

    const currentWeek = DateService.getCurrentWeekNumber(DateService.getCurrentDate());

    const [weekNumber, setWeek] = useState(currentWeek);

    const handleJobDataUpdate = useCallback((idjob, hoursDay) => {
        setDriverJobData(idjob, hoursDay)
    }, [setDriverJobData]);

    const handlegetTimesheetByDriver = useCallback((driverId) => {
        getTimesheetByDriver(dateFrom, dateTo, driverId)
    }, [dateFrom, dateTo, getTimesheetByDriver]);

    useWebServiceWithDate(timesheetStatus, getTimesheet, dateFrom, dateTo);

    useErrorHandler(timesheetStatus, errorCode, true);

    useEffect(() => {
        setTimeout(() => {
            getTimesheet(dateFrom, dateTo);
        }, 0);
    }, [dateFrom, dateTo, getTimesheet]);

    useEffect(() => {
        setFilteredTimesheetData(timesheetData);
      }, [timesheetData])

    const [state, setState] = useState({
        hoverRange: undefined,
        selectedDays: [],
    });

    const handleWeekClick = useCallback((weekNumber, days, e) => {
        setWeek(weekNumber);
        setStartSelectedDate(DateService.convertDate(days[0]));
        setEndSelectedDate(DateService.convertDate(days[6]));
        setStateToDefault();
        setState({
            selectedDays: days,
        });
        setAnchorEl(null);
    }, [setStateToDefault]);

    const handleSearch = (event) => {
        if (event.key !== 'Enter') {
          return;
        }
        let searchText = event.target.value;
        const searchedTimesheetData = timesheetData.timesheetData.filter(({
          driver_details: {
            driver_name,
          },
        }) => driver_name.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredTimesheetData({ timesheetData: searchedTimesheetData });
        setSearchedForText(true);
    }

    const { hoverRange, selectedDays } = state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'week-select-popover' : undefined;

    return (
        <>
            <TopTitle title={`${useLocalized('nav_weekly_record')}`} subtitle={`${useLocalized('timesheet_subtitle')}`} />
            <Grid container spacing={3}>
                <div className="right-button-select-section">
                    <Grid item xs={12} className="start-end-date-send-button">
                        <SearchInput handleSearch={handleSearch} />
                        <div>
                            <InputLabel className="simple-select-label">{labelWeekSelect}</InputLabel>
                            <div className="select-week-input" aria-describedby={id} onClick={handleClick}>
                                {DateService.getSplitDate(dateFrom)['year']}
                                &nbsp;-&nbsp;
                                {DateService.getSplitDate(dateTo)['year']}
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <DayPicker
                                    selectedDays={selectedDays}
                                    showWeekNumbers
                                    showOutsideDays
                                    modifiers={modifiers}
                                    onWeekClick={handleWeekClick}
                                    firstDayOfWeek={1}
                                />
                            </Popover>
                        </div>
                    </Grid>
                </div>
            </Grid>
            {(( filteredTimesheetData.timesheetData && filteredTimesheetData.timesheetData.length > 0)) ? (
                <DriversTimesheet
                    timesheets={filteredTimesheetData}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    weekNumber={weekNumber}
                    setDriverJobData={handleJobDataUpdate}
                    getTimesheetByDriver={handlegetTimesheetByDriver}
                />
            ) : (
                searchedForText && <div className="search-error-msg"><p>{searchErrorMsg}</p></div>
            )}
            {timesheetStatus === LOADING && timesheetData.length === 0 && <div className="loading-icon"><CircularProgress /></div>}
            {timesheetStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
}

Timesheets.propTypes = {
    timesheets: PropTypes.object,
    getTimesheet: PropTypes.func,
    setDriverJobData: PropTypes.func,
    setStateToDefault: PropTypes.func,
};

export default connect(({
    timesheets,
}) => ({
    timesheets,
}), {
    getTimesheet,
    setStateToDefault,
    setDriverJobData,
    getTimesheetByDriver
})(Timesheets);
