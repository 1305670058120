import { createContext } from 'react';
import englishText from '../Data/Translation.json'
import { supportedLocales } from '../Constants';

class LocalizationService {
  static getCurrentTranslations = (locale) => {
    switch (locale) {
      case supportedLocales.en_GB:
        return englishText;
      default:
        return englishText;
    }
  }

  static getLocalizedText(locale, text) {
    const currentTranslations = this.getCurrentTranslations(locale);
    if (!currentTranslations[text]) {
      return text;
    }
    return currentTranslations[text];
  }

  static getLocalizedErrorMessage(locale, errorCode) {
    const currentTranslations = this.getCurrentTranslations(locale);
    if (!currentTranslations[`errorCode_${errorCode}`]) {
      return currentTranslations['somethingHappened'];
    }
    return currentTranslations[`errorCode_${errorCode}`];
  }
}

export const LocalizedContext = createContext();

export default LocalizationService;
