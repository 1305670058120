import DriverService from '../../Services/DriverService';

import {
  DRIVERS_LOADING,
  DRIVERS_SUCCESS,
  DRIVERS_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  DRIVER_STATUS_UPDATE_LOADING,
  DRIVER_STATUS_UPDATE_SUCCESS,
  DRIVER_STATUS_UPDATE_FAILED,
  DRIVER_CHANGE,
  DRIVER_DELETE_SUCCESS,
  DRIVER_DELETE_LOADING,
  DRIVER_DELETE_FAILED,
  DRIVER_DETAIL_RELOAD
} from '../../Redux/ActionTypes';

const initialState = {
  status: DEFAULT,
  driverUpdateStatus: DEFAULT,
  data: [],
  errorCode: '',
  drivers: [],
}

const driverReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DRIVERS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVERS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        drivers: payload,
      };
    case DRIVERS_FAIL:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case DRIVER_STATUS_UPDATE_LOADING:
      return {
        ...state,
        driverUpdateStatus: LOADING,
      };
    case DRIVER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        driverUpdateStatus: SUCCESS,
      };
    case DRIVER_STATUS_UPDATE_FAILED:
      return {
        ...state,
        driverUpdateStatus: FAIL,
        errorCode: payload.err,
      };
    case DRIVER_CHANGE:
      return {
        ...state,
        status: SUCCESS,
        drivers: DriverService.getUpdatedDrivers(state.drivers, payload),
      };
    case DRIVER_DELETE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_DELETE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        drivers: state.drivers.filter(item => item.id !== payload.driverId),
      };
    case DRIVER_DELETE_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case DRIVER_DETAIL_RELOAD:
      return {
        ...state,
        status: DEFAULT,
      };
    default:
      return state;
  }
};

export default driverReducer;
