import ScheduleService from '../../Services/ScheduleService';

import {
  SCHEDULES_LOADING,
  SCHEDULES_FAILED,
  SCHEDULES_SUCCESS,
  SUCCESS,
  FAIL,
  LOADING,
  DEFAULT,
  GET_AVAILABLE_ORDER_LOADING,
  GET_AVAILABLE_ORDER_SUCCESS,
  GET_AVAILABLE_ORDER_FAILED,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
  CREATE_SCHEDULE_LOADING,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE_STATUS_LOADING,
  UPDATE_SCHEDULE_STATUS_SUCCESS,
  UPDATE_SCHEDULE_STATUS_FAILED,
  SCHEDULE_RELOAD,
} from '../../Redux/ActionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
  errorCode: '',
}

const schedulesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SCHEDULES_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SCHEDULES_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload,
      };
    case SCHEDULES_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case GET_AVAILABLE_ORDER_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case GET_AVAILABLE_ORDER_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        availableOrders: payload,
      };
    case GET_AVAILABLE_ORDER_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case DELETE_SCHEDULE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: {
          scheduleData: ScheduleService.deleteSchedule(state.data.scheduleData, payload)
        },
      };
    case DELETE_SCHEDULE_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case CREATE_SCHEDULE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: {
          scheduleData: ScheduleService.addSchedule(state.data.scheduleData, payload)
        },
      };
    case CREATE_SCHEDULE_FAILED:
      return {
        ...state,
        status: DEFAULT,
        errorCode: payload.err,
      };
    case UPDATE_SCHEDULE_STATUS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case UPDATE_SCHEDULE_STATUS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: {
          scheduleData: ScheduleService.updateScheduleStatus(state.data.scheduleData, payload)
        },
      };
    case UPDATE_SCHEDULE_STATUS_FAILED:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err,
      };
    case SCHEDULE_RELOAD:
      return {
        ...state,
        status: DEFAULT,
      };
    default:
      return state;
  }
};

export default schedulesReducer;
