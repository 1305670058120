import { makeStyles } from '@mui/styles';

const focusedColor = '#029EFF';
const useMuiTheme = makeStyles({
  root: {
    '&$checked': {
      color: '#3D70B2',
    },
    "& .MuiFilledInput-root": {
        background: "white"
    },
    // input label when focused
    "& label.Mui-focused": {
      color: focusedColor
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: focusedColor
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: focusedColor
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: focusedColor,
        },
      "&.Mui-focused fieldset": {
        borderColor: focusedColor
      }
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#B8B8B8',
    },
  }
});

export default useMuiTheme;
