import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateDriverStatus, changeDriver } from '../../Redux/Actions/DriverActions';
import ApproveMark from '../../Assets/approveMark.svg';
import DeclineMark from '../../Assets/declineMark.svg';

var statusActive = { status: 'Active' };
var statusRejected = { status: 'Rejected' };

function InactiveDriverTableCellBody({ column, value, accept, reject, changeDriver, updateDriverStatus }) {

  const handleUpdateDriverSuccess = useCallback((status) => {
    changeDriver(value, status);
  }, [changeDriver, value]);

  if (column.id === 'number') {
    return (
      <div>#{value}</div>
    )
  } else if (column.id === 'colorCode') {
    return (
      <div className="inactive-color-line"></div>
    )
  } else if (column.id === 'id') {

    return (
      <div className="driver-operations">
        <Link to="#" onClick={() => updateDriverStatus(value, statusActive, handleUpdateDriverSuccess)} className="button">
          <img alt='ApproveMark' src={ApproveMark} />
        </Link>
        <Link to="#" onClick={() => updateDriverStatus(value, statusRejected, handleUpdateDriverSuccess)} className="button">
          <img alt='DeclineMark' src={DeclineMark} />
        </Link>
      </div>
    )
  } else {
    return (
      <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    drivers: { status },
  } = state;
  return { status };
};

export default connect(mapStateToProps, { changeDriver, updateDriverStatus })(InactiveDriverTableCellBody);
