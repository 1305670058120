import {
  ORDERS_LOADING,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_LOADING,
  ORDER_CREATE_LOADING,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
} from '../ActionTypes';

const initialState = {
  status: DEFAULT,
  deleteStatus: DEFAULT,
  createStatus: DEFAULT,
  data: [],
  errorCode: '',
}

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //Load all orders
    case ORDERS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case ORDERS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload.orders,
      };
    case ORDERS_FAIL:
      return {
        ...state,
        status: FAIL,
        errorCode: payload.err
      };
    //Delete order
    case ORDER_DELETE_LOADING:
      return {
        ...state,
        deleteStatus: LOADING,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        ...state,
        status: DEFAULT,
        deleteStatus: SUCCESS,
      };
    case ORDER_DELETE_FAIL:
      return {
        ...state,
        deleteStatus: FAIL,
        errorCode: payload.err
      };
      //Create order
      case ORDER_CREATE_LOADING:
        return {
          ...state,
          createStatus: LOADING,
        };
      case ORDER_CREATE_SUCCESS:
        return {
          ...state,
          status: DEFAULT,
          createStatus: SUCCESS,
        };
      case ORDER_CREATE_FAIL:
        return {
          ...state,
          createStatus: FAIL,
          errorCode: payload.err
        };
    default:
      return state;
  }
};

export default ordersReducer;