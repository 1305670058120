import { supportedLocales } from '../../Constants';

import {
  LOGGING_IN_LOADING,
  LOGGING_IN_FAILED,
  LOGGING_IN_SUCCESS,
  SET_LOGIN_TO_DEFAULT,
  DEFAULT,
  SUCCESS,
  FAIL,
  LOADING,
  LOG_OUT,
} from '../ActionTypes';

const initialState = {
  user: {
    locale: supportedLocales.en_GB,
    token: '',
    loginStatus: DEFAULT,
    errorCode: '',
  },
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGGING_IN_LOADING:
      return {
        ...state,
        user: {
          ...state.user,
          loginStatus: LOADING,
        },
      };
    case LOGGING_IN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          token: payload.token,
          loginStatus: SUCCESS,
        },
      };
    case LOGGING_IN_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          loginStatus: FAIL,
          errorCode: payload.err
        },
      };
      case SET_LOGIN_TO_DEFAULT:
        return {
          ...state,
          user: {
            ...state.user,
            loginStatus: DEFAULT,
          },
        };
        case LOG_OUT:
          return initialState
    default:
      return state;
  }
};

export default userReducer;
