import WebService from '../../Services/WebServices';
import {
  ORDERS_LOADING,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
  ORDER_DELETE_LOADING,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_CREATE_LOADING,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
} from '../../Redux/ActionTypes';
import { ORDERS } from '../../Constants/options';

export const getOrders = (newObjectOnPage = 10, newPageNumber = 1, oldObjectOnPage = 10, oldPageNumber = 1, currentOrders) => async (dispatch) => {
  dispatch({ type: ORDERS_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${ORDERS}?new_objects_on_page=${newObjectOnPage}&new_page_number=${newPageNumber}&old_page_number=${oldPageNumber}&old_objects_on_page=${oldObjectOnPage}`,
    };

    const orders = await WebService.getSharedInstance().callWebservice(
      options,
    );
    
    if (currentOrders !== undefined) {
        var new_orders_ids = new Set(currentOrders.new_orders.map(order => order.id));
        orders.new_orders = [...currentOrders.new_orders, ...orders.new_orders.filter(d => !new_orders_ids.has(d.id))];

        var old_orders_ids = new Set(currentOrders.old_orders.map(order => order.id));
        orders.old_orders = [...currentOrders.old_orders, ...orders.old_orders.filter(d => !old_orders_ids.has(d.id))];
    }

    dispatch({
      type: ORDERS_SUCCESS,
      payload: {
        orders
      },
    });
  } catch (err) {
    dispatch({ 
      type: ORDERS_FAIL,
      payload: {
        err,
      } 
    });
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  dispatch({ type: ORDER_DELETE_LOADING });
  try {
    let options = {
      method: 'DELETE',
      path: `${ORDERS}/${id}`
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: ORDER_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({ 
      type: ORDER_DELETE_FAIL,
      payload: {
        err,
      } 
    });
  }
};

export const createOrder = (companyId, startDate, endDate) => async (dispatch) => {
  dispatch({ type: ORDER_CREATE_LOADING });
  try {
    let options = {
      method: 'POST',
      path: `${ORDERS}`,
      data: {
        company_id: companyId,
        start_date: startDate,
        end_date: endDate,
      }
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: ORDER_CREATE_SUCCESS,
    });
  } catch (err) {
    dispatch({ 
      type: ORDER_CREATE_FAIL, 
      payload: {
        err,
      }
    });
  }
};
