import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DateService from '../../Services/DateService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import useLocalized from '../../Data/Localization';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EventNoteIcon from '@material-ui/icons/EventNote';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SettingsGear from '../../Assets/settings-gear.svg';
import EmailSendIcon from '../../Assets/email-send-icon.svg';
import XMark from '../../Assets/xmark.svg';
import Modal from 'react-modal';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    accordion: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.04)',
        width: '99.3%',
    },
    accordionContent: {
        margin: '0'
    },
    table: {
        minWidth: 1700,
    },
});

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: '90%',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const WeeklyRecordList = ({
    weeklyRecord,
    weekNumber,
    dateFrom,
    dateTo,
    handleDownloadWeek,
    partnerContacts,
    emailAction,
    partnerSaveAction
}) => {
    const classes = useStyles();

    const weeklyRecordWarehouseLabel = useLocalized('invoice_warehouse_label');
    const detailsLabel = useLocalized('details_button');
    const driversLabel = useLocalized('nav_drivers');
    const vehicleRegNr = useLocalized('vehicle_reg_number');
    const managerLabel = useLocalized('job_tracking_manager_label');
    const startTimeLabel = useLocalized('start_time_label');
    const finishTimeLabel = useLocalized('finish_time_label');
    const noWeekRecord = useLocalized('no_weekly_record');
    const workHoursLabel = useLocalized('work_hours_label');
    const overtimeLabel = useLocalized('overtime_label');
    const driverSignatureLabel = useLocalized('driver_signature_label');
    const managerSignatureLabel = useLocalized('manager_signature_label');
    const taxZoneLabel = useLocalized('tax_zone_label');
    const addEmailPlaceholder = useLocalized('add_email_placeholder');
    
    const [download, setDownload] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [openSettingsPopUp, setOpeSettingsPopUp] = useState(false);
    const [emails, setEmails] = useState({});
    const [selectedWeeklyRecord, setSelectedWeeklyRecord] = useState();

    let [selectedPartners, setSelectedPartners] = useState([]);

    const selectAll = useCallback((e) => {
        if (!e.target.checked) {
            setSelectedPartners([]);
            return;
        }
        setSelectedPartners([...weeklyRecord.map(({ partner_id }) => partner_id)])
    }, [weeklyRecord]);

    const selectItem = useCallback((e, id) => {
        if (!e.target.checked) {
            setSelectedPartners(currentSelectedPartners => currentSelectedPartners.filter(({ partner_id }) => partner_id === id))
            return;
        }
        setSelectedPartners(currentSelectedPartners => [...currentSelectedPartners, id]);
    }, []);

    const checkIfSelected = useCallback((id) => {
        return selectedPartners.includes(id);
    }, [selectedPartners]);

    const handleDownload = useCallback(() => {
        setDownload(!download);
        handleDownloadWeek(dateFrom, dateTo, selectedPartners);
    }, [dateFrom, dateTo, download, handleDownloadWeek, selectedPartners])

    const handleEmailSending = useCallback((partnerId) => {
        emailAction(dateFrom, dateTo, partnerId);
    }, [dateFrom, dateTo, emailAction])

    const buildEmailList = useCallback((weeklyRecordItem) => {
        if (partnerContacts !== undefined) {
            const filteredPartner = <>{partnerContacts.find(element => element.id === weeklyRecordItem.partner_id).partnerContactEmails.map(
                (item) => <Typography style={{ paddingLeft: 20 }}>{item.email}</Typography>
            )}</>

            return filteredPartner
        }

        return null
    }, [partnerContacts]);

    const handlePartnerUpdate = useCallback((partnerId) => {
        partnerSaveAction({partner_contact_emails: Object.values(emails)}, partnerId);
        setOpeSettingsPopUp((oldValue) => !oldValue);
        window.location.reload();
    }, [emails, partnerSaveAction])
    
    const buildEmailModal = useCallback(() => {
        return (
            selectedWeeklyRecord && (
                <Modal style={customStyles} isOpen={openPopUp} onRequestClose={() => {setOpenPopUp((oldValue) => !oldValue)}}>
                <div className='popUpContainer'>
                    <img src={EmailSendIcon} alt="EmailSendIcon" />
                    <div className='weekly-record-send-message'>
                        Are you sure you want to send weekly report:
                    </div>
                    <div className='weekly-record-send-text-partner'>
                        {'W' + weekNumber + ' ' + (selectedWeeklyRecord.partner_name ?? '') + ' ' + selectedWeeklyRecord.partner_location}
                    </div>
                    <div className='weekly-record-send-text'>
                        to
                    </div>
                    <div className='weekly-record-send-text-emails'>
                        {buildEmailList(selectedWeeklyRecord)}
                    </div>
                    <div className='weekly-record-send-button-container'>
                        <button className='weekly-record-send-button-yes' onClick={() => {handleEmailSending(selectedWeeklyRecord.partner_id)}}>Yes</button>
                        <button className='weekly-record-send-button-no' onClick={() => {setOpenPopUp((oldValue) => !oldValue)}}>No</button>
                    </div>
                </div>
            </Modal>
            )
        )
    }, [buildEmailList, handleEmailSending, openPopUp, selectedWeeklyRecord, weekNumber]);

    const buildSettingModal = useCallback(() => {
        return (
            selectedWeeklyRecord && (
                <Modal style={customStyles} isOpen={openSettingsPopUp} onRequestClose={() => {setOpeSettingsPopUp((oldValue) => !oldValue)}}>
                <div className='popUpContainer'>
                    <div className='title-container'>
                        <div>
                        <div className="title">
                            Email manage
                        </div>
                        <div className="sub-title">
                        edit or add emails
                        </div>
                        </div>
                        <img alt='XMark' src={XMark} onClick={() => setOpeSettingsPopUp((oldValue) => !oldValue)} />
                    </div>
                    <div className='add-email-textfield-container'>
                        <div className='weekly-record-send-text-partner'>
                            Partner emails
                        </div>
                        <input
                            className="email-textfield"
                            type="email"
                            placeholder={addEmailPlaceholder}
                            name='partner_contact_emails1'
                            onChange={(e) => setEmails({...emails, [e.target.name]: e.target.value})}
                        />
                    </div>
                    <div className='add-email-textfield-container'>
                        <div className="text-field-title-placeholder" />
                        <input
                            className="email-textfield"
                            type="email"
                            placeholder={addEmailPlaceholder}
                            name='partner_contact_emails2'
                            onChange={(e) => setEmails({...emails, [e.target.name]: e.target.value})}
                        />
                    </div>
                    <div className='add-email-textfield-container'>
                        <div className="text-field-title-placeholder" />
                        <input
                            className="email-textfield"
                            type="email"
                            placeholder={addEmailPlaceholder}
                            name='partner_contact_emails3'
                            onChange={(e) => setEmails({...emails, [e.target.name]: e.target.value})}
                        />
                    </div>
                    <div className='add-email-textfield-container'>
                        <div className="text-field-title-placeholder" />
                        <input
                            className="email-textfield"
                            type="email"
                            placeholder={addEmailPlaceholder}
                            name='partner_contact_emails4'
                            onChange={(e) => setEmails({...emails, [e.target.name]: e.target.value})}
                        />
                    </div>
                    <div className='add-email-textfield-container'>
                        <div className="text-field-title-placeholder" />
                        <input
                            className="email-textfield"
                            type="email"
                            placeholder={addEmailPlaceholder}
                            name='partner_contact_emails5'
                            onChange={(e) => setEmails({...emails, [e.target.name]: e.target.value})}
                        />
                    </div>
                    <button className='button' onClick={() => handlePartnerUpdate(selectedWeeklyRecord.partner_id)}>Save</button>
                </div>
            </Modal>
            )
        )
    }, [addEmailPlaceholder, emails, handlePartnerUpdate, openSettingsPopUp, selectedWeeklyRecord]);

    return (
        <>  
            {buildEmailModal()}
            {buildSettingModal()}
            <div className="weekly-record-grid-style">
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <div className="week-nr-col">W{weekNumber}</div>
                    </Grid>
                    {weeklyRecord.length === 0 && <div className="no-data-message no-invoice-added"><EventNoteIcon />{noWeekRecord}</div>}
                    <Grid item xs={11}>
                        <div className="scrolling-wrapper-flexbox">
                            <div className={classes.root}>
                                {weeklyRecord.length > 0 && 
                                    <div className="weekly-record-header-section">
                                        <div className="warehouse-col">
                                            <div>
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    id="allSelect"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    onClick={selectAll}
                                                    checked={selectedPartners.length === weeklyRecord.length}
                                                />
                                                <GetAppOutlinedIcon onClick={handleDownload}
                                                    color={download ? "primary" : "inherit"}
                                                    style={{cursor: 'pointer'}}
                                                />
                                                &nbsp;&nbsp;
                                                {weeklyRecordWarehouseLabel}
                                            </div>
                                            <div className="dot-tip-text">
                                                <div className="dot-tip" />
                                                <div>Includes extra taxes</div>
                                                <div className="dot-inactive-tip" />
                                                <div>No extra tax</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {weeklyRecord.map((weeklyRecordItems) => (
                                    <Accordion className={classes.accordion}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div className="warehouse-name">
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    checked={checkIfSelected(weeklyRecordItems.partner_id)}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    onChange={(e) => selectItem(e, weeklyRecordItems.partner_id)}
                                                />
                                                {weeklyRecordItems.has_pay_zones ? <div className="dot" /> : <div className="dot-inactive" />}
                                                {weeklyRecordItems.partner_name + " - " + weeklyRecordItems.partner_location}
                                            </div>
                                        </AccordionSummary>
                                        <div className="weekly-record-divider"><Divider /></div>
                                        <AccordionDetails>
                                            <TableContainer className="weekly-record-table">
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow style={{ verticalAlign: 'unset' }}>
                                                            <TableCell>
                                                                <div>{detailsLabel}</div>
                                                                <div className="date-range">{DateService.getSplitDate(weeklyRecordItems.date_from)['day']}/{DateService.getSplitDate(weeklyRecordItems.date_from)['month']} - {DateService.getSplitDate(weeklyRecordItems.date_to)['day']}/{DateService.getSplitDate(weeklyRecordItems.date_to)['month']}</div>
                                                            </TableCell>
                                                            <TableCell>{driversLabel}</TableCell>
                                                            <TableCell>{vehicleRegNr}</TableCell>
                                                            <TableCell style={{ verticalAlign: 'unset', borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}>{managerLabel}</TableCell>
                                                            <TableCell />
                                                            <TableCell>{startTimeLabel}</TableCell>
                                                            <TableCell>{finishTimeLabel}</TableCell>
                                                            <TableCell>{workHoursLabel}</TableCell>
                                                            <TableCell>{overtimeLabel}</TableCell>
                                                            <TableCell>{taxZoneLabel}</TableCell>
                                                            <TableCell>{managerSignatureLabel}</TableCell>
                                                            <TableCell style={{ verticalAlign: 'unset', borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}>{driverSignatureLabel}</TableCell>
                                                            <TableCell style={{ paddingLeft: 20 }}>email manager</TableCell>
                                                            <TableCell>
                                                                <img src={SettingsGear} alt="SettingsGear" onClick={() => {
                                                                    setOpeSettingsPopUp((oldValue) => !oldValue)
                                                                    setSelectedWeeklyRecord(weeklyRecordItems)
                                                                }} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {weeklyRecordItems.daily_details.map((dailyDetailList) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell style={{ verticalAlign: 'unset' }}>
                                                                        {DateService.getDayName(dailyDetailList.date)}
                                                                    </TableCell>
                                                                    <TableCell style={{ verticalAlign: 'unset' }}/>
                                                                    <TableCell style={{ verticalAlign: 'unset' }}/>
                                                                    <TableCell style={{ verticalAlign: 'unset', borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}/>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell style={{ verticalAlign: 'unset', borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}/>
                                                                    <TableCell />
                                                                </TableRow>
                                                                {dailyDetailList.details.map((detailItem) => (
                                                                    <TableRow>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}></TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.driver}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.vehicle_reg_number !== null ? detailItem.vehicle_reg_number : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset',    borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.manager_name !== null ? detailItem.manager_name : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <ListItemText style={{ marginRight: 25 }} />
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.start_time !== null ? DateService.getBritishTime(dailyDetailList.date, detailItem.start_time) : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.finish_time !== null ? DateService.getBritishTime(dailyDetailList.date, detailItem.finish_time) : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.work_hours !== null ? detailItem.work_hours : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.overtime !== null ? detailItem.overtime : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    {detailItem.weekly_record_pay_zones.length > 0 ? <div className="dot" /> : <div className="dot-inactive" />}
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.weekly_record_pay_zones.length > 0 ? detailItem.weekly_record_pay_zones.map(zone => zone.zone_name).join(', ') : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset' }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.signature_path.length > 1 ? 'Signed' : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'unset', borderRightStyle: "solid", borderRightColor: "#DDDDDD",}}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.driver_signature_path.length > 1? 'Signed' : '-'}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: 'top' }}>
                                                                            {(dailyDetailList === weeklyRecordItems.daily_details[weeklyRecordItems.daily_details.length - 1] && detailItem === dailyDetailList.details[dailyDetailList.details.length - 1]) && (
                                                                                <>
                                                                                    {buildEmailList(weeklyRecordItems)}
                                                                                    <Button onClick={() => {
                                                                                        setOpenPopUp((oldValue) => !oldValue)
                                                                                        setSelectedWeeklyRecord(weeklyRecordItems)
                                                                                    }} style={{ margin: 20 }}>Send</Button>
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default WeeklyRecordList;
