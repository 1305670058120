import React from 'react';
import PropTypes from 'prop-types';
import DateService from '../../Services/DateService';

const JobTrackingNotification = ({
  notifications,
}) => {

  return (
    <div className="history-notes-section">
      {notifications.map((notification) => {
        return (
          <div className="driver-notes-list" key={notification.id}>
            <div className="note-date">{DateService.getSplitDate(notification.date)['year']}</div>
            <div className="note-content">
                <div className="note-list-grid">
                <div className="history-title">{notification.event_type}</div>
                <div>{notification.description}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

JobTrackingNotification.propTypes = {
    notifications: PropTypes.array,
};

export default JobTrackingNotification;
