import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  LOCATION_LOADING,
  LOCATION_FAILED,
  LOCATION_SUCCESS,
} from '../ActionTypes';

const initialState = {
  locationStatus: DEFAULT,
  locationData: [],
  errorCode: '',
};

const locationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_LOADING:
      return {
        ...state,
        locationStatus: LOADING,
      };
    case LOCATION_SUCCESS:
      return {
        ...state,
        locationData: payload,
        locationStatus: SUCCESS,
      };
    case LOCATION_FAILED:
      return {
        ...state,
        locationStatus: FAIL,
        errorCode: payload.err,
      };
    default:
      return state;
  }
};

export default locationReducer;
