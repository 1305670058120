import WebService from '../../Services/WebServices';
import {
  DRIVERS_LOADING,
  DRIVERS_SUCCESS,
  DRIVERS_FAIL,
  DRIVER_STATUS_UPDATE_LOADING,
  DRIVER_STATUS_UPDATE_FAILED,
  DRIVER_CHANGE,
  DRIVER_DELETE_LOADING,
  DRIVER_DELETE_SUCCESS, 
  DRIVER_DELETE_FAILED,
  DRIVER_DETAIL_RELOAD
} from '../../Redux/ActionTypes';
import { DRIVERS } from '../../Constants/options';

export const getDrivers = () => async (dispatch) => {
  dispatch({ type: DRIVER_DETAIL_RELOAD })

  dispatch({ type: DRIVERS_LOADING });
  const driverUrl = DRIVERS;
  try {
    let options = {
      method: 'GET',
      path: driverUrl,
    };

    const drivers = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type: DRIVERS_SUCCESS,
      payload: drivers,
    });
  } catch (err) {
    dispatch({
      type: DRIVERS_FAIL,
      payload: {
        err
      }
    });
  }
};

export const changeDriver = (id, object) => {
  return {
    type: DRIVER_CHANGE,
    payload: {
      id,
      object,
    }
  }
}

export const updateDriverStatus = (driverId, dataStatus, success, deleteDate) => async (dispatch) => {
  dispatch({ type: DRIVER_STATUS_UPDATE_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${DRIVERS}/${driverId}`,
      data: {
            status: dataStatus.status,
        }
    };

    if (deleteDate) {
      options.data.deleted_from = deleteDate
    }

    await WebService.getSharedInstance().callWebservice(options);
    window.location.reload()

  } catch (err) {
    dispatch({
      type: DRIVER_STATUS_UPDATE_FAILED,
      payload: {
        err,
      }
    });
  }
};

export const deleteDriver = (driverId) => async (dispatch) => {
  dispatch({ type: DRIVER_DELETE_LOADING });
  try {
    let options = {
      method: 'DELETE',
      path: `${DRIVERS}/${driverId}`,
    };

    await WebService.getSharedInstance().callWebservice(options);
    dispatch({
      type: DRIVER_DELETE_SUCCESS,
      payload: {driverId},
    });

  } catch (err) {
    dispatch({
      type: DRIVER_DELETE_FAILED,
      payload: {
        err,
      }
    });
  }
};

export const setStateToDefault = () => async (dispatch) => {
  dispatch({ 
    type: DRIVER_DETAIL_RELOAD
  });
  
};
