import React from 'react';
import { NavLink } from 'react-router-dom';
import useLocalized from '../../Data/Localization';
import { Button } from '@material-ui/core';

function NavigationItems({action}){

	const logOutButton = useLocalized('log_out_button_text')

	return(
		<div className="primary-left-menulist">
			<ul className="navigation-list">
				<li className="nav-link">
					<NavLink to="/drivers" className="link-name">
						{useLocalized('nav_drivers')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/partners" className="link-name">
						{useLocalized('nav_partners')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/orders" className="link-name">
						{useLocalized('nav_orders')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/schedule" className="link-name">
						{useLocalized('nav_schedule')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/timesheets" className="link-name">
						{useLocalized('nav_weekly_record')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/invoice" className="link-name">
						{useLocalized('nav_invoice')}
					</NavLink>
				</li>
				<li className="nav-link">
					<NavLink to="/weekly-record" className="link-name">
						{useLocalized('nav_timesheets')}
					</NavLink>
				</li>
				<Button className={"remove-button"} onClick={() => action()}>{logOutButton}</Button>
			</ul>
		</div>
	);
}
export default NavigationItems;
