import React from 'react';
import { Button } from '@material-ui/core';
import { orderStatusColors } from '../../Constants/options';
import useLocalized from '../../Data/Localization';
import DateService from '../../Services/DateService';

function OrderTableCellBody({ column, value, j, action }) {
  const removeText = useLocalized('remove');
  switch (column.id) {
    case 'nr':
      return (
        <div>#{j + 1}</div>
      )
    case 'location':
      if (value !== null && value !== '-') {
        return (
          <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
        )
      } else {
        const defaultlocationtext = '-';
        return (
          <div className="text">{defaultlocationtext}</div>
        )
      }
    case 'id':
      return (
        <div className="edit-drivers">
          <Button className={"remove-button"} onClick={() => action(value)}>{removeText}</Button>
        </div>
      )
    case 'start_date':
      return (
        <div className="text">{DateService.getSplitDate(value)['year']}</div>
      )
    case 'end_date':
      return (
        <div className="text">{DateService.getSplitDate(value)['year']}</div>
      )
    case '-':
      return (
        <div className="text">{'-'}</div>
      )
    case 'order_already_assigned':
      if (value === 'True')
        return (
          <div className="grade-circle-cell"><span style={{ backgroundColor: orderStatusColors.ASSIGNED }} className="grade-circle"></span></div>
        )
      return (
        <div className="grade-circle-cell"><span style={{ backgroundColor: orderStatusColors.NOT_ASSIGNED }} className="grade-circle"></span></div>
      )
    default:
      return (
        <div className="text">{column.format && typeof value === 'number' ? column.format(value) : value}</div>
      )
  }
}

export default OrderTableCellBody;
