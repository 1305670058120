import { unionBy } from 'lodash';

class InvoiceService {

    static getUpdatedInvoices = (invoices, payload) => {

        const { id: idToFind, object } = payload;

        let invoiceToUpdate = invoices.find(o => o.invoice.id === idToFind);

        if (!invoiceToUpdate) {
            return invoices;
        }

        invoiceToUpdate = {
            ...invoiceToUpdate,
            invoice: {
                ...invoiceToUpdate.invoice,
                ...object,
            },
        };
        const mergedInvoices = unionBy([invoiceToUpdate], invoices, 'invoice.id');
        const sortedInvoices = this.getOrderedInvoices(mergedInvoices);

        return sortedInvoices;
    };

    static getOrderedInvoices = (invoices) => {
        return invoices.sort(({
            invoice: {
                partner_name: a1,
                partner_location: a2,
            }
        }, { 
            invoice: {
                partner_name: b1,
                partner_location: b2,
            }
        }) => `${a1}${a2}` < `${b1}${b2}` ? -1 : 1);
    }
}

export default InvoiceService;
