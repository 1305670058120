import WebService from '../../Services/WebServices';
import TimesheetService from '../../Services/TimesheetService';
import {
  TIMESHEETS_LOADING,
  TIMESHEETS_FAILED,
  TIMESHEETS_SUCCESS,
  SCHEDULE_RELOAD,
  TIMESHEET_UPDATE_LOADING,
  TIMESHEET_UPDATE_SUCCESS,
  TIMESHEET_UPDATE_FAILED,
  DRIVER_TIMESHEET_NOTE_LOADING,
  DRIVER_TIMESHEET_NOTE_SUCCESS,
  DRIVER_TIMESHEET_NOTE_FAILED,
  TIMESHEET_BY_DRIVER_LOADING,
  TIMESHEET_BY_DRIVER_SUCCESS,
  TIMESHEET_BY_DRIVER_FAILED,
  NOTE_CHANGE,
} from '../../Redux/ActionTypes';
import { TIMESHEETS, JOBS } from '../../Constants/options';

export const getTimesheet = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({ type: TIMESHEETS_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${TIMESHEETS}?date_from=${dateFrom}&date_to=${dateTo}`,
    };
    const timesheets = await WebService.getSharedInstance().callWebservice(
      options,
    );

    const orderedTimeSheets = TimesheetService.getOrderedTimeSheets(timesheets);

    dispatch({
      type: TIMESHEETS_SUCCESS,
      payload: {
        timesheetData: orderedTimeSheets
      },
    });
  } catch (err) {
    dispatch({
      type: TIMESHEETS_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const getTimesheetByDriver = (dateFrom, dateTo, driverId) => async (dispatch) => {
  dispatch({ type: TIMESHEET_BY_DRIVER_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${TIMESHEETS}?date_from=${dateFrom}&date_to=${dateTo}&driver_id=${driverId}`,
    };
    const timesheet = await WebService.getSharedInstance().callWebservice(
      options,
    );
    dispatch({
      type: TIMESHEET_BY_DRIVER_SUCCESS,
      payload: {
        timesheet,
        driverId
      }
    });
  } catch (err) {
    dispatch({
      type: TIMESHEET_BY_DRIVER_FAILED,
      payload: {
        err,
      },
    });
  }
};


export const setStateToDefault = () => async (dispatch) => {
  dispatch({
    type: SCHEDULE_RELOAD
  });
};

export const setDriverJobData = (idjob, dataStatus, success) => async (dispatch) => {

  dispatch({ type: TIMESHEET_UPDATE_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${JOBS}/${idjob}`,
      data: dataStatus,
    };

    await WebService.getSharedInstance().callWebservice(options);


    dispatch({
      type: TIMESHEET_UPDATE_SUCCESS,
    });

    success();

  } catch (err) {
    dispatch({
      type: TIMESHEET_UPDATE_FAILED,
      payload: {
        err
      },
    });
  }
};

export const setNoteToTimesheet = (driverId, dateFrom, dateTo, noteTimesheet, success) => async (dispatch) => {

  dispatch({ type: DRIVER_TIMESHEET_NOTE_LOADING });
  try {
    let options = {
      method: 'PUT',
      path: `${TIMESHEETS}`,
      data: {
        note: noteTimesheet,
        date_from: dateFrom,
        date_to: dateTo,
        driver_id: driverId
      }
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: DRIVER_TIMESHEET_NOTE_SUCCESS,
      payload: {
        noteTimesheet,
        driverId
      }
    });

    success(noteTimesheet, driverId);

  } catch (err) {
    dispatch({
      type: DRIVER_TIMESHEET_NOTE_FAILED,
      payload: {
        err
      },
    });
  }
};

export const changeNote = (note, driverId) => {
  return {
    type: NOTE_CHANGE,
    payload: {
      note,
      driverId,
    }
  }
}

