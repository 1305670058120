import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useLocalized from '../../Data/Localization';
import CompanyInvoiceList from '../../Components/CompanyInvoiceList/CompanyInvoiceList';
import EventNoteIcon from '@material-ui/icons/EventNote';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    accordion: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.04)',
        width: 'fit-content',
    },
    accordionContent: {
        margin: '0'
    }
});

const InvoiceSummary = ({
    dateFrom,
    dateTo,
    weekNumber,
    invoices,
}) => {
    const classes = useStyles();
    const noInvoice = useLocalized('no_invoice');
    const invoiceWarehouseLabel = useLocalized('invoice_warehouse_label');
    const invoiceTotalHoursLabel = useLocalized('invoice_total_hours_label');
    const invoiceTotalOvertimeLabel = useLocalized('invoice_total_overtime_label');
    const vanVehicleType = useLocalized('vehicle_type_van');
    const hiabVehicleType = useLocalized('vehicle_type_hiab');
    const specialVehicleType = useLocalized('vehicle_type_special');
    const vanPayrollVehicleType = useLocalized('vehicle_type_van_payroll');
    const hiabPayrollVehicleType = useLocalized('vehicle_type_hiab_payroll');
    const loftLVehicleType = useLocalized('vehicle_type_loft_l');
    const sevenTonsVehicleType = useLocalized('vehicle_type_seven_tons');
    const invoiceTotalAmountLabel = useLocalized('invoice_total_amount_label');
    const invoiceOrderNrLabel = useLocalized('invoice_order_nr_label');
    const invoiceNrLabel = useLocalized('invoice_nr_label');
    const ordersTableHeadCellStatus = useLocalized('orders_table_head_cell_status');
    const invoiceDescription = useLocalized('invoice_description');
    const preinvoiceSent = useLocalized('preinvoice_sent_label');
    const poReceived = useLocalized('po_received_label');
    const invoiceSent = useLocalized('invoice_sent_label');
    const inProgress = useLocalized('in_progress_label');
    const taxZoneLabel = useLocalized('tax_zone_label');

    return (
        <>
            <div className="invoice-grid-style">
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <div className="week-nr-col">W{weekNumber}</div>
                    </Grid>
                    <Grid item xs={11}>
                        <div className="scrolling-wrapper-flexbox">
                            <div className={classes.root}>
                                {invoices.length > 0 && <div className="invoice-header-section">
                                    <div className="warehouse-col">{invoiceWarehouseLabel}</div>
                                    <div className="van-col">
                                        <div>{vanVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="van-col">
                                        <div>{vanPayrollVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div>{hiabVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div>{hiabPayrollVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div>{sevenTonsVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div>{loftLVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div>{specialVehicleType}</div>
                                        <div className="multiple-label-in-header">
                                            <div className="hours-overtime-col">{invoiceTotalHoursLabel}</div>
                                            <div className="hours-overtime-col">{invoiceTotalOvertimeLabel}</div>
                                        </div>
                                    </div>
                                    <div className="tax-zone-point-col">{taxZoneLabel}</div>
                                    <div className="total-amount-col">{invoiceTotalAmountLabel}</div>
                                    <div className="order-nr-col">{invoiceOrderNrLabel}</div>
                                    <div className="invoice-nr-col">{invoiceNrLabel}</div>
                                    <div className="status-col">{ordersTableHeadCellStatus}</div>
                                </div>
                                }
                                {invoices.length === 0 && <div className="no-data-message no-invoice-added"><EventNoteIcon />{noInvoice}</div>}
                                {invoices.map((invoicelist) => {
                                    return (
                                        <CompanyInvoiceList
                                            key={invoicelist.invoice.id}
                                            invoiceId={invoicelist.invoice.id}
                                            warehouse={invoicelist.invoice.partner_name}
                                            partnerLocation={invoicelist.invoice.partner_location}
                                            totalHoursVan={invoicelist.invoice.total_hours_van}
                                            totalHoursHiab={invoicelist.invoice.total_hours_hiab}
                                            overtimeVan={invoicelist.invoice.total_overtime_hours_van}
                                            overtimeHiab={invoicelist.invoice.total_overtime_hours_hiab}
                                            totalHoursSpecial={invoicelist.invoice.total_hours_special}
                                            overtimeSpecial={invoicelist.invoice.total_overtime_hours_special}
                                            totalAmount={invoicelist.invoice.total_amount}
                                            purchaseOrderNr={invoicelist.invoice.purchase_order_nr}
                                            invoiceNr={invoicelist.invoice.invoice_nr}
                                            statusInvoice={invoicelist.invoice.status}
                                            hasPayZone={invoicelist.invoice.has_pay_zones}
                                            invoiceDays={invoicelist.invoice_daily_detail}
                                            dateFrom={dateFrom}
                                            dateTo={dateTo}
                                            preinvoiceSent={preinvoiceSent}
                                            poReceived={poReceived}
                                            invoiceSent={invoiceSent}
                                            inProgress={inProgress}
                                            invoiceDescription={invoiceDescription}
                                            invoiceValues={invoicelist.invoice}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

InvoiceSummary.propTypes = {
    invoices: PropTypes.array,
};

export default InvoiceSummary;
