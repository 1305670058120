import React, { useState, useCallback, useEffect } from 'react';
import TopTitle from '../TopTitle/TopTitle';
import useLocalized from '../../Data/Localization';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import OrderTableCellBody from '../OrderTableCellBody/OrderTableCellBody';
import OldOrderTableCellBody from '../OldOlderTableCellBody/OldOrderTableCellBody';
import Spliter from '../../Components/Spliter/Spliter';
import { SPLITTER_COLOR } from '../../Constants/options';

const OrderSelector = ({
  orders,
  action,
  downloadAction,
}) => {

  const [newOrders, setNewOrders] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  const [page, setPage] = useState(parseInt(localStorage.getItem('newPageNumber')));
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
  const [pageOld, setPageOld] = useState(parseInt(localStorage.getItem('oldPageNumber')));
  const [rowsPerPageOld, setRowsPerPageOld] = useState(parseInt(localStorage.getItem('rowsPerPageOld')));

  useEffect(() => {
    setNewOrders(orders.new_orders);
    setOldOrders(orders.old_orders);

    if (parseInt(orders.new_orders.length) < parseInt(localStorage.getItem('oldNewOrderCount'))) {
      setPage(0)
      setRowsPerPage(10)
    }

    if (parseInt(orders.old_orders.length) < parseInt(localStorage.getItem('oldOldOrderCount'))) {
      setPageOld(0)
      setRowsPerPageOld(10)
    }
    
    localStorage.setItem('oldNewOrderCount', parseInt(orders.new_orders.length));
    localStorage.setItem('oldOldOrderCount', parseInt(orders.old_orders.length));
    localStorage.setItem('newPageNumber', parseInt(page));
    localStorage.setItem('oldPageNumber', parseInt(pageOld));
    localStorage.setItem('rowsPerPage', parseInt(rowsPerPage));
    localStorage.setItem('rowsPerPageOld', parseInt(rowsPerPageOld));

    if (isNaN(page) || isNaN(pageOld) || isNaN(rowsPerPage) || isNaN(rowsPerPageOld)) {
        localStorage.setItem('newPageNumber', 0)
        localStorage.setItem('oldPageNumber', 0)
        localStorage.setItem('rowsPerPage', 10)
        localStorage.setItem('rowsPerPageOld', 10)

        setPage(parseInt(localStorage.getItem('newPageNumber')))
        setRowsPerPage(parseInt(localStorage.getItem('rowsPerPage')))
        setPageOld(localStorage.getItem('oldPageNumber'))
        setRowsPerPageOld(localStorage.getItem('rowsPerPageOld'))
    }
  }, [orders, page, pageOld, rowsPerPage, rowsPerPageOld]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
        downloadAction(rowsPerPage, parseInt(localStorage.getItem('newPageNumber')) + 1, rowsPerPageOld, pageOld + 1, orders);
    }, 0);
  }, [downloadAction, orders, pageOld, rowsPerPage, rowsPerPageOld]);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTimeout(() => {
        downloadAction(parseInt(localStorage.getItem('rowsPerPage')), 1, rowsPerPageOld, pageOld + 1, orders);
    }, 0);
  }, [downloadAction, orders, pageOld, rowsPerPageOld]);

  const handleChangePageOld = useCallback((event, newPageOld) => {
    setPageOld(newPageOld);
    setTimeout(() => {
        downloadAction(rowsPerPage, page + 1, rowsPerPageOld, parseInt(localStorage.getItem('oldPageNumber')) + 1, orders);
    }, 0);
  }, [downloadAction, orders, page, rowsPerPage, rowsPerPageOld]);

  const handleChangeRowsPerPageOld = useCallback((event) => {
    setRowsPerPageOld(+event.target.value);
    setPageOld(0);
    setTimeout(() => {
        downloadAction(rowsPerPage, page + 1, parseInt(localStorage.getItem('rowsPerPageOld')), 1, orders);
    }, 0);
  }, [downloadAction, orders, rowsPerPage, page]);

  const labelNr = useLocalized('drivers_table_head_cell_nr');
  const labelCompanyName = useLocalized('orders_table_head_cell_company_name');
  const labelLocation = useLocalized('orders_table_head_cell_location');
  const labelStatus = useLocalized('orders_table_head_cell_status');
  const labelDaterange = useLocalized('orders_table_head_cell_date_range');

  const oldColumns = [
    { id: 'nr', label: labelNr, class: 'columns-grid nr-cell-old table-list-head-cell' },
    { id: 'company_name', label: labelCompanyName, class: 'driver-name columns-grid driver-name-cell-old table-list-head-cell' },
    { id: 'location', label: labelLocation, class: 'columns-grid location-cell-old table-list-head-cell' },
    { id: 'start_date', label: labelDaterange, class: 'columns-grid start-date-cell-old table-list-head-cell' },
    { id: '-', label: '', class: 'columns-grid spliter-cell-old table-list-head-cell' },
    { id: 'end_date', label: '', class: 'columns-grid end-date-cell-old table-list-head-cell' },
  ];

  const newColumns = [
    { id: 'nr', label: labelNr, class: 'columns-grid nr-cell table-list-head-cell' },
    { id: 'company_name', label: labelCompanyName, class: 'driver-name columns-grid driver-name-cell table-list-head-cell' },
    { id: 'location', label: labelLocation, class: 'columns-grid location-cell table-list-head-cell' },
    { id: 'start_date', label: labelDaterange, class: 'columns-grid start-date-cell table-list-head-cell' },
    { id: '-', label: '', class: 'columns-grid spliter-cell table-list-head-cell' },
    { id: 'end_date', label: '', class: 'columns-grid end-date-cell table-list-head-cell' },
    { id: 'order_already_assigned', label: labelStatus, class: 'columns-grid grade-cell table-list-head-cell' },
    { id: 'id', label: '', class: 'edit-cell table-list-head-cell' },
  ];

  return (
    <>
      <TopTitle title={useLocalized('orders_new_title')} />
      <Paper className="default-table-container">
        <TableContainer className="default-table-list">
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead className="table-list-head">
              <TableRow>
                {newColumns.map((column) => (
                  <TableCell
                    className={column.class}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="default-table-body">
              {newOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, j) => {
                return (
                  <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={j + 1}>
                    {newColumns.map((column) => {
                      const value = row[column.id];
                      const oldOrdersNr = parseInt(orders.old_orders_count);
                      const allNumber = page * rowsPerPage + j;
                      return (
                        <TableCell className={column.class} key={column.id}>
                          <OrderTableCellBody value={value} column={column} j={allNumber + oldOrdersNr} action={action} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orders.new_orders_count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Spliter color={SPLITTER_COLOR} width="95%" marginTop={50} />
      <div className="driver-inactive-registration-list">
        <TopTitle title={useLocalized('orders_old_title')} />
        <Paper className="default-table-container">
          <TableContainer className="default-table-list">
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead className="table-list-head">
                <TableRow>
                  {oldColumns.map((column) => (
                    <TableCell
                      className={column.class}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="default-table-body">
                {oldOrders.slice(pageOld * rowsPerPageOld, pageOld * rowsPerPageOld + rowsPerPageOld).map((row, i) => {
                  return (
                    <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={i + 1}>
                      {oldColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell className={column.class} key={column.id}>
                            <OldOrderTableCellBody value={value} column={column} i={pageOld * rowsPerPageOld + i} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={orders.old_orders_count}
            rowsPerPage={rowsPerPageOld}
            page={pageOld}
            onChangePage={handleChangePageOld}
            onChangeRowsPerPage={handleChangeRowsPerPageOld}
          />
        </Paper>
      </div>
    </>
  );
};


export default OrderSelector;
