import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateService from '../../Services/DateService';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useLocalized from '../../CustomHooks/useLocalized';
import Typography from '@material-ui/core/Typography';
import { FORMAT_DATE } from '../../Constants/options';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    }
  }
}));

const multipleOrderOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const OrderPopup = ({partnersData, action}) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [date_from, setStartSelectedDate] = useState(DateService.getCurrentDate());
  const [date_to, setEndSelectedDate] = useState(DateService.getCurrentDate());
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const companyNamePlaceholder = useLocalized('add_order_company_name_placeholder');
  const locationPlaceholder = useLocalized('add_order_location_placeholder');
  const startDatePlaceholder = useLocalized('add_order_start_date_placeholder');
  const endDatePlaceholder = useLocalized('add_order_end_date_placeholder');
  const multipleOrdersPlaceholder = useLocalized('add_order_multiple_order_placeholder');
  const dateShouldNotBeBeforeStartDateErrorMessage = useLocalized('date_should_not_be_before_start_date_error_message');

  const [filteredLocations, setFilteredLocations] = useState([]);


  let [multipleOrderNumber, setMultipleOrderNumber] = useState('');

  const getFilteredCompanyNames = () => {
    const filteredCompanyNames = []
    if (partnersData.partnersData != undefined) {
      partnersData.partnersData.map((company) => {return filteredCompanyNames.push(company.companyName)})
      return [...new Set(filteredCompanyNames)]
    } 
    return []
  }

  const getFilteredLocationsByName = (name) => {
    let locations = []
    partnersData.partnersData.map((company) => {
       if (name === company.companyName) {
        locations.push({location: company.location, companyId: company.id})
      }
      return null;
    })
    
    locations.sort((a, b) => {
      const locationA = a.location.toLowerCase()
      const locationB = b.location.toLowerCase()
      return (locationA > locationB ? 1 : (locationA === locationB ? 0 : -1))
    });

    setFilteredLocations(locations);
  }

  const dateValidation = useCallback((startDate, lastDate) => {
    if (lastDate >= startDate) {
      return false
    }
    return true
  },[])

  const handleSave = () => {
    if (multipleOrderNumber) {
        function multipleOrderTimeout(number) {
          setTimeout(() => {
            action(selectedLocationId, date_from, date_to);
          }, number * 200);
        }
        for (var i = 0; i < multipleOrderNumber; i++) {
          multipleOrderTimeout(multipleOrderNumber);
        }
    } else {
      action(selectedLocationId, date_from, date_to)
    }
  }

  return (
    <div className="order-popup">
      <Button className="button" variant="outlined" onClick={handleClickOpen}>
        {useLocalized('add_button_text')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
        <div className="container">
          <div className="inner-container">
              <Typography className="title">
                {useLocalized('add_order_title')}
              </Typography>
              <Typography className="sub-title">
                {useLocalized('add_order_subtitle')}
              </Typography>
              <Typography className="text-field-title">
                {useLocalized('add_order_company_name_title')}
              </Typography>
              <Autocomplete
                onChange={(event, companyName) => {
                  getFilteredLocationsByName(companyName);
                }}
                onInputChange={(event, newInputValue) => {
                  getFilteredLocationsByName(newInputValue);
                }}
                id="company-name-box"
                classes={classes}
                className="general-width-to-inputs"
                options={getFilteredCompanyNames()}
                getOptionLabel={(option) => option}
                style={{ marginTop: 5 }}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="" placeholder={companyNamePlaceholder} />
                )}
              />
              <Typography className="text-field-title">
                {useLocalized('add_order_location_title')}
              </Typography>
              <Autocomplete
                onChange={(event, location) => {
                  setSelectedLocationId(location.companyId);
                }}
                onInputChange={(event, newInputValue) => {
                  setSelectedLocationId(newInputValue.companyId);
                }}
                id="location-box"
                classes={classes}
                options={filteredLocations}
                getOptionLabel={(option) => option.location}
                style={{ marginTop: 5 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="" placeholder={locationPlaceholder} />
                )}
              />
              <Typography className="text-field-title">
                {useLocalized('add_order_start_date_title')}
              </Typography>
               <div className="input-empty-space">
                  <div className="add-orderdate-select general-width-to-inputs">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format={FORMAT_DATE}
                      margin="normal"
                      id="date-picker-inline-start"
                      value={date_from}
                      autoOk
                      minDate={DateService.getCurrentDate()}
                      minDateMessage={dateShouldNotBeBeforeStartDateErrorMessage}
                      placeholder={startDatePlaceholder}
                      onChange={(startDate) => {
                        if (startDate) {
                          setStartSelectedDate(DateService.convertDate(startDate));
                        }
                      }}
                    />
                  </div>
                </div>
                <Typography className="text-field-title">
                  {useLocalized('add_order_end_date_title')}
                </Typography>
                <div className="input-empty-space">
                  <div className="add-orderdate-select general-width-to-inputs">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format={FORMAT_DATE}
                      margin="normal"
                      id="date-picker-inline-end"
                      value={date_to}
                      minDate={date_from}
                      autoOk
                      minDateMessage={dateShouldNotBeBeforeStartDateErrorMessage}
                      placeholder={endDatePlaceholder}
                      onChange={(endDate) => {
                        if (endDate) {
                          setEndSelectedDate(DateService.convertDate(endDate));
                        }
                      }}
                    />
                  </div>
                </div>
                <Typography className="text-field-title">
                {useLocalized('add_order_multiple_order')}
                </Typography>
                <Autocomplete
                  onChange={(event, value) => setMultipleOrderNumber(value)}
                  onInputChange={(event, value) => setMultipleOrderNumber(value)}
                  id="multiple-orders-box"
                  classes={classes}
                  className="general-width-to-inputs"
                  options={multipleOrderOptions}
                  getOptionLabel={(option) => option}
                  style={{ marginTop: 5 }}
                  fullWidth={true}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="" placeholder={multipleOrdersPlaceholder} />
                  )}
                />
              <Button disabled={dateValidation(date_from, date_to)} style={{ height: 40, marginTop: 20 , marginBottom: 30 }} onClick={handleSave}>
                {useLocalized('save_button_text')}
              </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default OrderPopup;
