import WebService from '../../Services/WebServices';
import {
  PARTNERS_UPDATE_LOADING,
  PARTNERS_UPDATE_SUCCESS,
  PARTNERS_UPDATE_FAILED,
} from '../../Redux/ActionTypes';
import { PARTNERS } from '../../Constants/options';

export const setPartnerData = (
  partnerId,
  partner,
  success,
) => async (dispatch) => {

  dispatch({ type: PARTNERS_UPDATE_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${PARTNERS}/${partnerId}`,
      data: partner
    };

    await WebService.getSharedInstance().callWebservice(options);

    success();

    dispatch({
      type: PARTNERS_UPDATE_SUCCESS,
    });

  } catch (err) {
    dispatch({
      type: PARTNERS_UPDATE_FAILED,
      payload: {
        err
      },
    });
  }
};
