import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { unionBy, isEqual } from 'lodash';
import useLocalized from '../../Data/Localization';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopTitle from '../../Components/TopTitle/TopTitle';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import { getPartners, createPartner } from '../../Redux/Actions/PartnerActions';
import { setPartnerData } from '../../Redux/Actions/PartnerDataAction';
import { SUCCESS, LOADING, FAIL} from '../../Redux/ActionTypes';
import PartnerList from '../../Components/PartnerList/PartnerList';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import PartnerPopup from '../../Components/PartnerPopup/PartnerPopup';

const Partners = ({
  partners: {
    status: partnerStatus,
    data: partnerData,
  },
  getPartners,
  createPartner,
  setPartnerData
}) => {

  useEffect(() => {
    setTimeout(() => {
      getPartners();
    }, 0);
  }, [getPartners]);

  const [presentedPartners, setPresentedPartners] = useState([]);
  const [partnerToSave, setPartnerToSave] = useState({});
  const [disabledUpdateButton, setDisableUpdateButton] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);

  const dataSavedMessage = useLocalized('partners_data_successfully_updated');

  const getSortedPartners = useCallback((partners) => {
    return partners.sort(({ id: a }, { id: b }) => a.localeCompare(b));
  }, []);

  useEffect(() => {
    if (partnerData.partnersData) {
      setPresentedPartners(getSortedPartners(partnerData.partnersData));
    }
  }, [getSortedPartners, partnerData]);

  const handlePartnerDataChange = useCallback((columnId, rowIndex, value) => {
    setPresentedPartners(currentPartners => {
      const newRow = {
        ...currentPartners[rowIndex],
        [columnId]: value,
      };
      setPartnerToSave(newRow);
      const newArray = unionBy([newRow], currentPartners, 'id');
      return getSortedPartners(newArray);
    })
  }, [getSortedPartners]);

  const canSave = useMemo(() => {
    return !isEqual(presentedPartners, partnerData.partnersData)
  }, [partnerData.partnersData, presentedPartners]);

  const savePartnerData = useCallback((event) => {
    const editedPartner = {};

    editedPartner.hourly_rate_van = partnerToSave.hourlyRateVan;
    editedPartner.overtime_hourly_rate_van = partnerToSave.overtimeHourlyRateVan;
    editedPartner.hourly_rate_hiab = partnerToSave.hourlyRateHiab;
    editedPartner.overtime_hourly_rate_hiab = partnerToSave.overtimeHourlyRateHiab;
    editedPartner.hourly_rate_special = partnerToSave.hourlyRateSpecial;
    editedPartner.overtime_hourly_rate_special = partnerToSave.overtimeHourlyRateSpecial;
    editedPartner.hourly_rate_van_payroll = partnerToSave.hourlyRateVanPayroll;
    editedPartner.overtime_hourly_rate_van_payroll = partnerToSave.overtimeHourlyRateVanPayroll;
    editedPartner.hourly_rate_hiab_payroll = partnerToSave.hourlyRateHiabPayroll;
    editedPartner.overtime_hourly_rate_hiab_payroll = partnerToSave.overtimeHourlyRateHiabPayroll;
    editedPartner.hourly_rate_seven_tons = partnerToSave.hourlyRateSevenTons;
    editedPartner.overtime_hourly_rate_seven_tons = partnerToSave.overtimeHourlyRateSevenTons;
    editedPartner.hourly_rate_loft_l = partnerToSave.hourlyRateLoftL;
    editedPartner.overtime_hourly_rate_loft_l = partnerToSave.overtimeHourlyRateLoftL;

    setPartnerData(partnerToSave.id, editedPartner, "PARTNER_CHANGE");
    setDisableUpdateButton(false);

    if (partnerStatus === "SUCCESS") {
      setSuccessMessage(dataSavedMessage);
    } setTimeout(() => setSuccessMessage(""), 5000);

    window.location.reload();
  },[partnerToSave.overtimeHourlyRateLoftL, partnerToSave.hourlyRateLoftL, partnerToSave.overtimeHourlyRateSevenTons, partnerToSave.hourlyRateSevenTons, partnerToSave.overtimeHourlyRateHiabPayroll, partnerToSave.hourlyRateHiabPayroll, partnerToSave.overtimeHourlyRateVanPayroll, partnerToSave.hourlyRateVanPayroll, dataSavedMessage, partnerStatus, partnerToSave.hourlyRateHiab, partnerToSave.hourlyRateVan, partnerToSave.id, partnerToSave.overtimeHourlyRateHiab, partnerToSave.overtimeHourlyRateVan, setPartnerData, partnerToSave.hourlyRateSpecial, partnerToSave.overtimeHourlyRateSpecial])

  const handleSave = useCallback((createPartnerData) => {
    createPartner(createPartnerData)
  }, [createPartner])

  return (
    <>
      <div className='title-container'>
        <TopTitle title={`${useLocalized('partners_title')}`} subtitle={`${useLocalized('partners_subtitle')}`} />
        <div>
          <button className='partner-buttons' disabled={!canSave || !disabledUpdateButton} onClick={savePartnerData}>
            {useLocalized('save_button_text')}
          </button>
          <button className='partner-buttons' onClick={() => setOpen((oldState) => !oldState)}>
            {useLocalized('new_partner_button_text')}
          </button>
        </div>
      </div>
      {<PartnerPopup action={handleSave} handleOpenClose={() => setOpen((oldState) => !oldState)} dialogOpen={open}/>}
      <FormHelperText component="div"><div className="saved-successfully-data-text partner-saved-text">{successMessage}</div></FormHelperText>
      {partnerStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
      {partnerStatus === SUCCESS && <PartnerList presentedPartners={presentedPartners} handlePartnerDataChange={handlePartnerDataChange} />}
      {partnerStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
    </>
  );
};

Partners.propTypes = {
  partners: PropTypes.object,
  getPartners: PropTypes.func,
  setPartnerData: PropTypes.func,
};

export default connect(({
  partners,
}) => ({
  partners,
}), {
  getPartners,
  createPartner,
  setPartnerData
})(Partners);
