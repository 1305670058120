import React from 'react';
import PropTypes from 'prop-types';
import DateService from '../../Services/DateService';

const DriverNotes = ({
  notes,
}) => {

  return (
    <div className="history-notes-section">
      {notes.map((note) => {
        return (
          <div className="driver-notes-list" key={note.id}>
            <div className="note-date">{DateService.getSplitDate(note.date)['year']}/W{DateService.getCurrentWeekNumber(note.date)}</div>
            <div className="note-content">{note.description}</div>
          </div>
        );
      })}
    </div>
  );
}

DriverNotes.propTypes = {
  notes: PropTypes.array,
};

export default DriverNotes;
