import TimesheetService from '../../Services/TimesheetService';

import {
  TIMESHEETS_LOADING,
  TIMESHEETS_FAILED,
  TIMESHEETS_SUCCESS,
  TIMESHEET_UPDATE_LOADING,
  TIMESHEET_UPDATE_SUCCESS,
  TIMESHEET_UPDATE_FAILED,
  SUCCESS,
  FAIL,
  LOADING,
  DEFAULT,
  SCHEDULE_RELOAD,
  DRIVER_TIMESHEET_NOTE_LOADING,
  DRIVER_TIMESHEET_NOTE_SUCCESS,
  DRIVER_TIMESHEET_NOTE_FAILED,
  TIMESHEET_BY_DRIVER_LOADING,
  TIMESHEET_BY_DRIVER_SUCCESS,
  TIMESHEET_BY_DRIVER_FAILED,
  NOTE_CHANGE,
  TIMESHEET_CHANGE
} from '../../Redux/ActionTypes';

const initialState = {
  status: DEFAULT,
  errorCode: '',
  data: [],
}

const timesheetsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TIMESHEETS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case TIMESHEETS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload,
      };
    case TIMESHEETS_FAILED:
      return {
        ...state,
        status: FAIL,
      };
    case SCHEDULE_RELOAD:
      return {
        ...state,
        status: DEFAULT,
      };
    case TIMESHEET_UPDATE_LOADING:
      return {
        ...state,
        status: SUCCESS,
      };
    case TIMESHEET_UPDATE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
      };
    case TIMESHEET_UPDATE_FAILED:
      return {
        ...state,
        status: TIMESHEET_UPDATE_FAILED,
        errorCode: payload.err,
      };
    case TIMESHEET_BY_DRIVER_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case TIMESHEET_BY_DRIVER_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: {
          timesheetData: TimesheetService.getUpdatedTimesheet(state.data.timesheetData, payload)
        },
      };
    case TIMESHEET_BY_DRIVER_FAILED:
      return {
        ...state,
        status: TIMESHEET_UPDATE_FAILED,
        errorCode: payload.err,
      };
    case DRIVER_TIMESHEET_NOTE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case DRIVER_TIMESHEET_NOTE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
      };
    case DRIVER_TIMESHEET_NOTE_FAILED:
      return {
        ...state,
        status: FAIL,
      };
    case NOTE_CHANGE:
      return {
        ...state,
        status: SUCCESS,
        data: {
          timesheetData: TimesheetService.updateTimesheetDriverNote(state.data.timesheetData, payload)
        },
      };
    case TIMESHEET_CHANGE:
      return {
        ...state,
        status: SUCCESS,
        data: {
          timesheetData: TimesheetService.getUpdatedTimesheet(state.data.timesheetData, payload)
        }
      };
    default:
      return state;
  }
};

export default timesheetsReducer;
