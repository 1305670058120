import WebService from '../../Services/WebServices';
import {
  LOGGING_IN_LOADING,
  LOGGING_IN_FAILED,
  LOGGING_IN_SUCCESS,
  SET_LOGIN_TO_DEFAULT,
  LOG_OUT,
} from '../ActionTypes';
import { ADMIN_SCOPE, LOGIN } from '../../Constants/options';

export const loginAction = (loginEmail, password) => async (dispatch) => {
  dispatch({ type: LOGGING_IN_LOADING });
    const scope = [ADMIN_SCOPE];
    const tokenUrl = LOGIN;

  try {
    let options = {
      method: 'POST',
      path: tokenUrl,
      data: { email: loginEmail, password, scope },
    };

    const loginData = await WebService.getSharedInstance().callWebservice(
      options,
    );
    const { token, email } = loginData;

    dispatch({
      type: LOGGING_IN_SUCCESS,
      payload: {
        token,
        email,
      },
    });
  } catch (err) {
    dispatch({ 
      type: LOGGING_IN_FAILED, 
      payload: {
        err,
      } 
    });
  }
};

export const logOutAction = () => (dispatch) => {
  dispatch({ type: LOG_OUT });
  WebService.getSharedInstance().setToken('');
};

export const setLoginPageToDeafult = () => (dispatch) => {
  dispatch({ type: SET_LOGIN_TO_DEFAULT });
};
