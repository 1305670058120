import React from 'react';
import { CURRENCY_SYMBOL } from '../../Constants/options';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const CellTextField = ({ value, j, handleEdit, vehicleType }) => {
  return (
    <TextField
      style={{ width: 80, backgroundColor: 'white' }}
      value={value} 
      onChange={(e) => handleEdit(vehicleType, j, e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment style={{ paddingLeft: '5px' }} position="start">
            {CURRENCY_SYMBOL}
          </InputAdornment>
        ),
      }}
      type="float"
    />
  )
}

export default CellTextField
