import PartnerService from '../../Services/PartnerService';

import {
    PARTNERS_DATA_LOADING,
    PARTNERS_DATA_SUCCESS,
    PARTNERS_DATA_FAILED,
    DEFAULT,
    LOADING,
    SUCCESS,
    FAIL,
    PARTNER_CHANGE
  } from '../../Redux/ActionTypes';
  
  const initialState = {
    status: DEFAULT,
    data: [],
    partner: {
        id: '',
        companyName: '',
        hourly_rate_van: '',
        overtime_hourly_rate_van: '',
        hourly_rate_hiab: '',
        overtime_hourly_rate_hiab: '',
        partnerSince: '',
        location: ''
    }
  }
  
  const partnerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case PARTNERS_DATA_LOADING:
        return {
          ...state,
          status: LOADING,
        };
      case PARTNERS_DATA_SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          data: payload,
        };
      case PARTNER_CHANGE:
        return {
          ...state,
          status: SUCCESS,
          partner: PartnerService.getUpdatedPartners(state.partner, payload),
        };
      case PARTNERS_DATA_FAILED:
        return {
          ...state,
          status: FAIL,
        };
      default:
        return state;
    }
  };
  
  export default partnerReducer;
