import React from 'react';
import PropTypes from 'prop-types';
import useLocalized from '../../Data/Localization';
import Grid from '@material-ui/core/Grid';
import { jobTrackingStatusColors } from '../../Styles/colors';
import EventNoteIcon from '@material-ui/icons/EventNote';
import JobTrackingNotification from '../../Components/JobTrackingNotification/JobTrackingNotification';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { marker } from '../../Assets';
import L from 'leaflet';
import { IMAGE_BASE_URL } from '../../Constants/config';

const JobData = ({
    job,
}) => {
    const labelDriverNr = useLocalized('drivers_table_head_cell_nr');
    const labelDriverName = useLocalized('drivers_table_head_cell_driver_name');
    const labelNotification = useLocalized('job_tracking_notification_label');
    const labelWarehouse = useLocalized('invoice_warehouse_label');
    const labelStatus = useLocalized('orders_table_head_cell_status');
    const labelDuration = useLocalized('job_tracking_duration_label');
    const labelManager = useLocalized('job_tracking_manager_label');
    const labelManagerSignature = useLocalized('job_tracking_manager_signature_label');
    const labelDriverMap = useLocalized('job_tracking_driver_map_label');
    const no_notification = useLocalized('job_tracking_notifications_empty');
    const managerSignatureAltText = useLocalized('alt_text_to_manager_signature');
    const noCoordinates = useLocalized('no_coordinates_to_map');
    const routeSentToDriverStatus = useLocalized('route_sent_to_driver_status_text');

    const iconDriver = new L.Icon({
        iconUrl: marker,
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(16, 16),
        className: 'map-icon-style'
    });

    return (
        <div className="job-tracking-screen">
            <Grid container>
                <Grid item className="job-left-side" xs={5}>
                    <Grid container>
                        <Grid item xs={5}>
                            <label className="labelCustom">{labelDriverNr}</label>
                            <div className="driver-nr">
                                <div className="driver-info">
                                    <div className="driver-color-row" style={{ backgroundColor: '#' + job.job_details.driver_color }}></div>
                                    <div className="driver-nr">#{job.job_details.driver_number}</div>
                                </div>
                            </div>
                            <div className="white-box">
                                <label className="labelCustom">{labelDriverName}</label>
                                <div className="driver-name style-bold">{job.job_details.driver_name}</div>
                            </div>
                            <div className="white-box">
                                <label className="labelCustom">{labelWarehouse}</label>
                                <div className="input-name style-bold">{job.job_details.order_company} {job.job_details.order_location}</div>
                            </div>
                            <div className="white-box">
                                <label className="labelCustom">{labelStatus}</label>
                                <div className="input-name style-bold" style={{ color: '#' + jobTrackingStatusColors[job.job_details.status] }}>
                                    {job.job_details.status === 'Sent' ? routeSentToDriverStatus : job.job_details.status}
                                </div>
                            </div>
                            <div className="white-box">
                                <label className="labelCustom">{labelDuration}</label>
                                <div className="input-name style-bold">{job.job_details.duration}</div>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="notification-list">
                                <div><label className="labelCustom">{labelNotification}</label></div>
                                <div className="boxes-notification">
                                    {job.job_notifications.length === 0 && <div className="no-data-message"><EventNoteIcon />{no_notification}</div>}
                                    {job.job_notifications.length > 0 && <JobTrackingNotification notifications={job.job_notifications} />}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7}>
                    <div className="container-right-side">
                        <div className="driver-map">
                            <label className="labelCustom">{labelDriverMap}</label>
                            <div className="map-image">
                                {job.driver_position !== null && <MapContainer center={[job.driver_position.latitude, job.driver_position.longitude]} zoom={15} className="map-position" scrollWheelZoom={true}>
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                    />
                                    <Marker position={[job.driver_position.latitude, job.driver_position.longitude]} icon={iconDriver}></Marker>
                                </MapContainer>
                                }
                                {job.driver_position === null && <div className="no-data-message"><EventNoteIcon />{noCoordinates}</div>}
                            </div>
                        </div>
                        <Grid item xs={5}>
                            <div className="white-box">
                                <label className="labelCustom">{labelManager}</label>
                                <div className="input-name style-bold">{job.job_details.manager_name !== null ? job.job_details.manager_name : ''}</div>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <div className="manager-signature">
                                <label className="labelCustom">{labelManagerSignature}</label>
                                <div className="signature-image">
                                    {job.job_details.manager_signature !== null && <img src={`${IMAGE_BASE_URL}${job.job_details.manager_signature}`} className="sign-manager-image" alt={managerSignatureAltText} />}
                                </div>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

JobData.propTypes = {
    job: PropTypes.object,
};

export default JobData;
