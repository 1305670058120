import WebService from '../../Services/WebServices';
import InvoiceService from '../../Services/InvoiceService';
import {
  INVOICES_LOADING,
  INVOICES_FAILED,
  INVOICES_SUCCESS,
  INVOICE_RELOAD,
  INVOICE_UPDATE_LOADING,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_FAILED,
  INVOICE_CHANGE
} from '../../Redux/ActionTypes';
import { INVOICES } from '../../Constants/options';

export const getInvoice = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({ type: INVOICES_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${INVOICES}?date_from=${dateFrom}&date_to=${dateTo}`,
    };
    const invoices = await WebService.getSharedInstance().callWebservice(
      options,
    );

    const sortedInvoices = InvoiceService.getOrderedInvoices(invoices);

    dispatch({
      type: INVOICES_SUCCESS,
      payload: sortedInvoices

    });
  } catch (err) {
    dispatch({
      type: INVOICES_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const setStateToDefault = () => async (dispatch) => {
  dispatch({
    type: INVOICE_RELOAD
  });
};

export const changeInvoice = (id, object) => {
  return {
    type: INVOICE_CHANGE,
    payload: {
      id,
      object,
    }
  }
}

export const updateInvoiceData = (idInvoice, dataUpdate, success) => async (dispatch) => {

  dispatch({ type: INVOICE_UPDATE_LOADING });
  try {
    let options = {
      method: 'PATCH',
      path: `${INVOICES}/${idInvoice}`,
      data: dataUpdate,
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: INVOICE_UPDATE_SUCCESS,
    });

    success(dataUpdate);

  } catch (err) {
    dispatch({
      type: INVOICE_UPDATE_FAILED,
      payload: {
        err
      },
    });
  }
};
