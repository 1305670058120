import { LOCATION_LOADING, LOCATION_FAILED, LOCATION_SUCCESS } from '../ActionTypes';
import WebService from '../../Services/WebServices';
import { LOCATIONS } from '../../Constants/options';

export const getLocationList = () => async (dispatch) => {
  dispatch({ type: LOCATION_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${LOCATIONS}`,
    };
    const response = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type: LOCATION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_FAILED,
      payload: {
        err,
      },
    });
  }
};
