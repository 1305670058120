import React, { useCallback, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setDriverJobData } from '../../Redux/Actions/TimesheetActions';
import { timesheetVehicleType } from '../../Constants';
import DataParserService from '../../Services/DataParserService';

function DriversTimesheetCalendar({ hasHourlyRates, driverId, idjob, day, companyName, companyLocation, vehicleType, hours, overtimeHours, adminModifiedHours, adminModifiedOvertimeHours, setDriverJobData, getTimesheetByDriver, vehicleTypeAlertMessage }) {

    const scLocationDriver = `${companyName} ${companyLocation}`;

    const [hoursDay, setHour] = useState('');
    const [overtimeHoursJob, setOvertimeHours] = useState('');
    const [hoursHiabJob, setHoursHiabJob] = useState('');
    const [overtimeHoursHiab, setOvertimeHourHiabJob] = useState('');
    const [hoursSpecialJob, setHoursSpecialJob] = useState('');
    const [overtimeHoursSpecial, setOvertimeHourSpecialJob] = useState('');

    const [hoursVanPayrollJob, setHoursVanPayrollJob] = useState('');
    const [overtimeHoursVanPayrollJob, setOvertimeHoursVanPayrollJob] = useState('');
    const [hoursHiabPayrollJob, setHoursHiabPayrollJob] = useState('');
    const [overtimeHiabPayroll, setOvertimeHourHiabPayrollJob] = useState('');
    const [hoursSevenTonsJob, setHoursSevenTonsJob] = useState('');
    const [overtimeHoursSevenTons, setOvertimeHourSevenTonsJob] = useState('');
    const [hoursLoftLJob, setHoursLoftLJob] = useState('');
    const [overtimeHoursLoftL, setOvertimeHourLoftLJob] = useState('');

    const handleHourChange = (event, setHours, newVehicleType) => {
        let hourValue = event.target.value
        vehicleType = newVehicleType
        if (DataParserService.isNumber(hourValue) && idjob !== undefined) {
            if (!hourValue) {
                hourValue = 0.0;
            }
            setHours(hourValue);
        }
    };

    const saveDriverJobData = (idjob, jsonValue, hour, clickedVehicleType, hourType, setHours) => {
        if (!hour) {
            hour = 0.0;
            jsonValue[hourType] = hour;
        }
        if (DataParserService.isNumber(hour) && idjob !== undefined) {
            if (clickedVehicleType === timesheetVehicleType.HIAB && vehicleType === timesheetVehicleType.VAN && (hoursDay !== 0.0 || overtimeHoursJob !== 0.0)) {
                alert(`${vehicleTypeAlertMessage}`);
                setHours(0);

            } else if (clickedVehicleType === timesheetVehicleType.VAN && vehicleType === timesheetVehicleType.HIAB && (hoursHiabJob !== 0.0 || overtimeHoursHiab !== 0)) {
                alert(`${vehicleTypeAlertMessage}`);
                setHours(0);
            }
            else {
                setDriverJobData(idjob, jsonValue, handleSuccessfullyUpdate);
            }
        }
    };

    const setDefaultHour = useCallback((hour, adminModifiedHour, vehicleTypeGiven) => {
        if (vehicleType !== vehicleTypeGiven) {
            return '';
        }
        if (adminModifiedHour !== null) {
            return adminModifiedHour;
        }
        if (hour !== null) {
            return hour;
        }
        return '';
    }, [vehicleType]);

    const handleSuccessfullyUpdate = () => {
        getTimesheetByDriver(driverId);
    };

    var newVanHour = { admin_modified_hours: hoursDay, vehicle_type: timesheetVehicleType.VAN };
    var newOvertimeHour = { admin_modified_overtime_hours: overtimeHoursJob, vehicle_type: timesheetVehicleType.VAN };
    var newHiabHour = { admin_modified_hours: hoursHiabJob, vehicle_type: timesheetVehicleType.HIAB };
    var newOvertimeHiabHour = { admin_modified_overtime_hours: overtimeHoursHiab, vehicle_type: timesheetVehicleType.HIAB };
    var newSpecialHour = { admin_modified_hours: hoursSpecialJob, vehicle_type: timesheetVehicleType.SPECIAL };
    var newOvertimeSpecialHour = { admin_modified_overtime_hours: overtimeHoursSpecial, vehicle_type: timesheetVehicleType.SPECIAL };
    var newVanPayrollHour = { admin_modified_hours: hoursVanPayrollJob, vehicle_type: timesheetVehicleType.VAN_PAYROLL };
    var newOvertimeVanPayrollHour = { admin_modified_overtime_hours: overtimeHoursVanPayrollJob, vehicle_type: timesheetVehicleType.VAN_PAYROLL };
    var newHiabPayrollHour = { admin_modified_hours: hoursHiabPayrollJob, vehicle_type: timesheetVehicleType.HIAB_PAYROLL };
    var newOvertimeHiabPayrollHour = { admin_modified_overtime_hours: overtimeHiabPayroll, vehicle_type: timesheetVehicleType.HIAB_PAYROLL };
    var newSevenTonsHour = { admin_modified_hours: hoursSevenTonsJob, vehicle_type: timesheetVehicleType.SEVEN_TONS };
    var newOvertimeSevenTonsHour = { admin_modified_overtime_hours: overtimeHoursSevenTons, vehicle_type: timesheetVehicleType.SEVEN_TONS };
    var newLoftLHour = { admin_modified_hours: hoursLoftLJob, vehicle_type: timesheetVehicleType.LOFT_L };
    var newOvertimeLoftLHour = { admin_modified_overtime_hours: overtimeHoursLoftL, vehicle_type: timesheetVehicleType.LOFT_L };

    useEffect(() => {
        setHour(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.VAN));
        setOvertimeHours(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.VAN));
        setHoursHiabJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.HIAB));
        setOvertimeHourHiabJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.HIAB));
        setHoursSpecialJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.SPECIAL));
        setOvertimeHourSpecialJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.SPECIAL));
        setHoursVanPayrollJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.VAN_PAYROLL));
        setOvertimeHoursVanPayrollJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.VAN_PAYROLL));
        setHoursHiabPayrollJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.HIAB_PAYROLL));
        setOvertimeHourHiabPayrollJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.HIAB_PAYROLL));
        setHoursSevenTonsJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.SEVEN_TONS));
        setOvertimeHourSevenTonsJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.SEVEN_TONS));
        setHoursLoftLJob(setDefaultHour(hours, adminModifiedHours, timesheetVehicleType.LOFT_L));
        setOvertimeHourLoftLJob(setDefaultHour(overtimeHours, adminModifiedOvertimeHours, timesheetVehicleType.LOFT_L));
    }, [adminModifiedHours, adminModifiedOvertimeHours, hours, overtimeHours, setDefaultHour, vehicleType]);

    return (
        <div className="week-col" key={day}>
            <div className="day-name"><strong>{day}</strong></div>
            <div className="timesheet-location">
                {scLocationDriver !== 'undefined undefined' ? scLocationDriver : '-'}
            </div>
            { hasHourlyRates.has_van_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        {}
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.VAN && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHour, timesheetVehicleType.VAN);
                            }}
                            value={hoursDay}
                            onBlur={() => saveDriverJobData(idjob, newVanHour, hoursDay, timesheetVehicleType.VAN, 'admin_modified_hours', setHour)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.VAN && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHours, timesheetVehicleType.VAN);
                            }}
                            value={overtimeHoursJob}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeHour, overtimeHoursJob, timesheetVehicleType.VAN, 'admin_modified_overtime_hours', setOvertimeHours)}
                            placeholder='0'
                        />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_van_payroll_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        {}
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.VAN_PAYROLL && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursVanPayrollJob, timesheetVehicleType.VAN_PAYROLL);
                            }}
                            value={hoursVanPayrollJob}
                            onBlur={() => saveDriverJobData(idjob, newVanPayrollHour, hoursVanPayrollJob, timesheetVehicleType.VAN_PAYROLL, 'admin_modified_hours', setHoursVanPayrollJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.VAN_PAYROLL && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHoursVanPayrollJob, timesheetVehicleType.VAN_PAYROLL);
                            }}
                            value={overtimeHoursVanPayrollJob}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeVanPayrollHour, overtimeHoursVanPayrollJob, timesheetVehicleType.VAN_PAYROLL, 'admin_modified_overtime_hours', setOvertimeHoursVanPayrollJob)}
                            placeholder='0'
                        />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_hiab_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.HIAB && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursHiabJob, timesheetVehicleType.HIAB);
                            }}
                            value={hoursHiabJob}
                            onBlur={() => saveDriverJobData(idjob, newHiabHour, hoursHiabJob, timesheetVehicleType.HIAB, 'admin_modified_hours', setHoursHiabJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.HIAB && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHourHiabJob, timesheetVehicleType.HIAB);
                            }}
                            value={overtimeHoursHiab}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeHiabHour, overtimeHoursHiab, timesheetVehicleType.HIAB, 'admin_modified_overtime_hours', setOvertimeHourHiabJob)}
                            placeholder='0' />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_hiab_payroll_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.HIAB_PAYROLL && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursHiabPayrollJob, timesheetVehicleType.HIAB_PAYROLL);
                            }}
                            value={hoursHiabPayrollJob}
                            onBlur={() => saveDriverJobData(idjob, newHiabPayrollHour, hoursHiabPayrollJob, timesheetVehicleType.HIAB_PAYROLL, 'admin_modified_hours', setHoursHiabPayrollJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.HIAB_PAYROLL && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHourHiabPayrollJob, timesheetVehicleType.HIAB_PAYROLL);
                            }}
                            value={overtimeHiabPayroll}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeHiabPayrollHour, overtimeHiabPayroll, timesheetVehicleType.HIAB_PAYROLL, 'admin_modified_overtime_hours', setOvertimeHourHiabPayrollJob)}
                            placeholder='0' />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_seven_tons_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.SEVEN_TONS && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursSevenTonsJob, timesheetVehicleType.SEVEN_TONS);
                            }}
                            value={hoursSevenTonsJob}
                            onBlur={() => saveDriverJobData(idjob, newSevenTonsHour, hoursSevenTonsJob, timesheetVehicleType.SEVEN_TONS, 'admin_modified_hours', setHoursSevenTonsJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.SEVEN_TONS && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHourSevenTonsJob, timesheetVehicleType.SEVEN_TONS);
                            }}
                            value={overtimeHoursSevenTons}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeSevenTonsHour, overtimeHoursSevenTons, timesheetVehicleType.SEVEN_TONS, 'admin_modified_overtime_hours', setOvertimeHourSevenTonsJob)}
                            placeholder='0' />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_loft_l_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.LOFT_L && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursLoftLJob, timesheetVehicleType.LOFT_L);
                            }}
                            value={hoursLoftLJob}
                            onBlur={() => saveDriverJobData(idjob, newLoftLHour, hoursLoftLJob, timesheetVehicleType.LOFT_L, 'admin_modified_hours', setHoursLoftLJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.LOFT_L && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHourLoftLJob, timesheetVehicleType.LOFT_L);
                            }}
                            value={overtimeHoursLoftL}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeLoftLHour, overtimeHoursLoftL, timesheetVehicleType.LOFT_L, 'admin_modified_overtime_hours', setOvertimeHourLoftLJob)}
                            placeholder='0' />
                    </div>
                </> )   
            }
            { hasHourlyRates.has_special_hourly_rate && (           
                <>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.SPECIAL && adminModifiedHours !== null && adminModifiedHours !== hours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setHoursSpecialJob, timesheetVehicleType.SPECIAL);
                            }}
                            value={hoursSpecialJob}
                            onBlur={() => saveDriverJobData(idjob, newSpecialHour, hoursSpecialJob, timesheetVehicleType.SPECIAL, 'admin_modified_hours', setHoursSpecialJob)}
                            placeholder='0'
                        />
                    </div>
                    <div className="timesheet-hours">
                        <TextField
                            label=""
                            className={`timesheet-mini-input ${vehicleType === timesheetVehicleType.SPECIAL && adminModifiedOvertimeHours !== null && adminModifiedOvertimeHours !== overtimeHours ? 'modified-driver-job-input' : ''}`}
                            onChange={(event) => {
                                handleHourChange(event, setOvertimeHourSpecialJob, timesheetVehicleType.SPECIAL);
                            }}
                            value={overtimeHoursSpecial}
                            onBlur={() => saveDriverJobData(idjob, newOvertimeSpecialHour, overtimeHoursSpecial, timesheetVehicleType.SPECIAL, 'admin_modified_overtime_hours', setOvertimeHourSpecialJob)}
                            placeholder='0' />
                    </div>    
                </> )   
            }
        </div>
    );
}

export default connect(null, { setDriverJobData })(DriversTimesheetCalendar);
