import WebService from '../../Services/WebServices';
import { 
  PARTNERS_LOADING,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,
  PARTNER_CREATE_LOADING,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAILED
} from '../../Redux/ActionTypes';
import { PARTNERS } from '../../Constants/options';

export const getPartners = () => async (dispatch) => {
  dispatch({ type: PARTNERS_LOADING });
  try {
    let options = {
        method: 'GET',
        path: PARTNERS,
    };

    const partners = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type: PARTNERS_SUCCESS,
      payload: {
        partnersData: partners
      },
    });
  } catch(err) {
      dispatch({ type: PARTNERS_FAIL });
  }
};

export const createPartner = (data) => async (dispatch) => {
  dispatch({ type: PARTNER_CREATE_LOADING });
  try {
    let options = {
      method: 'POST',
      path: PARTNERS,
      data: data
    };

    await WebService.getSharedInstance().callWebservice(options);

    dispatch({
      type: PARTNER_CREATE_SUCCESS,
    });
  } catch (err) {
    dispatch({ 
      type: PARTNER_CREATE_FAILED, 
      payload: {
        err,
      }
    });
  }
};
