import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useLocalized from '../../Data/Localization';
import { getJobById } from '../../Redux/Actions/JobDataAction';
import TopTitle from '../../Components/TopTitle/TopTitle';
import { SUCCESS, LOADING, FAIL } from '../../Redux/ActionTypes';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import JobData from '../../Components/JobData/JobData';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import { HashLink } from 'react-router-hash-link';

const JobDetail = ({
    jobDetail: {
        status: jobDataStatus,
        data: jobData,
    },

    getJobById,
    match: {
        params: {
            scheduleId,
        },
    },
}) => {

    useEffect(() => {
        setTimeout(() => {
            getJobById(scheduleId);
        }, 0);
      }, [getJobById, scheduleId]);

    
    const createHashLink = useCallback(() => {
        if (Object.keys(jobData).length === 0) {
            return `/schedule`
        }
        return `/schedule#${jobData.job_details.driver_name}`
    }, [jobData])

    return (
        <> 
            <div className="back-button-title">
                <HashLink to={ createHashLink() } className="back-button">{"<"}</HashLink>
                <TopTitle title={`${useLocalized('nav_job_title')}`} subtitle={`${useLocalized('nav_job_subtitle')}`} />
            </div>
            {jobDataStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
            {jobDataStatus === SUCCESS && <JobData job={jobData} />}
            {jobDataStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
};

JobDetail.propTypes = {
    jobDetail: PropTypes.object,
    getJobById: PropTypes.func,
};

export default withRouter(connect(({
    jobDetail,
}) => ({
    jobDetail,
}), {
    getJobById
})(JobDetail));
