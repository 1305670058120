import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useLocalized from '../../Data/Localization';

function Copyright() {
    return (
      <Typography className="copyright-section">
        {useLocalized('copyright_label')}
        {' '}
        <Link className="copyright-link" href="/">
          {useLocalized('copyright')}
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

export default Copyright;
