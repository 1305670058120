import PartnerService from '../../Services/PartnerService';

import {
  PARTNERS_LOADING,
  PARTNERS_SUCCESS,
  PARTNERS_FAIL,
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  PARTNER_CHANGE,
  PARTNER_CREATE_LOADING,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAILED
} from '../../Redux/ActionTypes';

const initialState = {
  status: DEFAULT,
  data: [],
}

const partnerDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PARTNERS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case PARTNERS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload,
      };
      case PARTNER_CHANGE:
      return {
        ...state,
        status: SUCCESS,
        partners: PartnerService.getUpdatedPartners(state.partners, payload),
      };
    case PARTNERS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
      // Create partner
      case PARTNER_CREATE_LOADING:
        return {
          ...state,
          createStatus: LOADING,
        };
      case PARTNER_CREATE_SUCCESS:
        return {
          ...state,
          status: DEFAULT,
          createStatus: SUCCESS,
        };
      case PARTNER_CREATE_FAILED:
        return {
          ...state,
          createStatus: FAIL,
          errorCode: payload.err
        };
    default:
      return state;
  }
};

export default partnerDataReducer;
