import { unionBy } from 'lodash';

class TimesheetService {

    static updateTimesheetDriverNote = (timesheetData, payload) => {

        let timesheetNoteToUpdate = timesheetData.find(o => o.driver_details.driver_id === payload.driverId);

        if (!timesheetNoteToUpdate) {
            return timesheetData;
        }

        timesheetNoteToUpdate.timesheet_note = payload.note

        return timesheetData;
    };

    static getUpdatedTimesheet = (timesheetData, payload) => {

        const { timesheet, driverId } = payload;

        let timesheetToUpdate = timesheetData.find(o => o.driver_details.driver_id === driverId);

        if (!timesheetToUpdate) {
            return timesheetData;
        }

        const mergedTimeSheetData = unionBy(timesheet, timesheetData, 'driver_details.driver_id');
        const sortedTimeSheetData = this.getOrderedTimeSheets(mergedTimeSheetData);

        return sortedTimeSheetData;
    };

    static getOrderedTimeSheets = (timeSheets) => {
        return timeSheets.sort(({ driver_details: { driver_number: a } }, { driver_details: { driver_number: b } }) => {
            if (a === null || a === undefined) {
              return 1;
            }
            if (b === null || b === undefined) {
              return -1;
            }
            if (a < b) {
              return -1;
            }
            return 1;
          });
    }
}

export default TimesheetService;
