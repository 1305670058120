import React from 'react';

const ColoredLine = ({ color, width, marginTop }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: width,
          marginTop: marginTop,
      }}
  />
);

export default ColoredLine
