import React, { useState, useCallback, useEffect, useMemo } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { connect } from 'react-redux';
import { setNoteToTimesheet, changeNote } from '../../Redux/Actions/TimesheetActions';
import useLocalized from '../../Data/Localization';

function TimesheetNote({ noteTimesheet, dateFrom, dateTo, driverId, setNoteToTimesheet, changeNote }) {

    const [noteTimesheetAdded, setNote] = useState(noteTimesheet);

    const handleAddNoteToTimesheet = (e) => {
        setNote(e.target.value);
    };

    const handleUpdateNoteSuccess = useCallback((note, driverId) => {
        changeNote(note, driverId);
    }, [changeNote]);

    const saveNoteToDriverTimesheet = useCallback((driverId, dateFrom, dateTo, noteTimesheetAddedNew) => {
        if (noteTimesheetAddedNew !== null && noteTimesheetAddedNew !== '') {
            setNoteToTimesheet(driverId, dateFrom, dateTo, noteTimesheetAddedNew, handleUpdateNoteSuccess);
        }
    }, [setNoteToTimesheet, handleUpdateNoteSuccess]);

    useEffect(() => {
        setNote(noteTimesheet);
    }, [noteTimesheet]);

    const driverTimesheetNotePlaceholder = useLocalized('driver_timesheet_note_placeholder');

    const noteAddedUsememo = useMemo(() => {
        return (
            <TextareaAutosize
                rowsMin="1"
                label=""
                onChange={handleAddNoteToTimesheet}
                value={noteTimesheetAdded}
                className="timesheet-note"
                placeholder={driverTimesheetNotePlaceholder}
                onBlur={() => saveNoteToDriverTimesheet(driverId, dateFrom, dateTo, noteTimesheetAdded)}
            />
        )

    }, [dateFrom, dateTo, driverId, driverTimesheetNotePlaceholder, noteTimesheetAdded, saveNoteToDriverTimesheet]);

    return noteAddedUsememo;
}

export default connect(null, { setNoteToTimesheet, changeNote })(TimesheetNote);
