import InvoiceService from '../../Services/InvoiceService';

import {
    INVOICES_LOADING,
    INVOICES_FAILED,
    INVOICES_SUCCESS,
    SUCCESS,
    FAIL,
    LOADING,
    DEFAULT,
    INVOICE_RELOAD,
    INVOICE_UPDATE_LOADING,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_FAILED,
    INVOICE_CHANGE
} from '../../Redux/ActionTypes';

const initialState = {
    status: DEFAULT,
    errorCode: '',
    invoices: [],
}

const invoiceReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case INVOICES_LOADING:
            return {
                ...state,
                status: LOADING,
            };
        case INVOICES_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                invoices: InvoiceService.getOrderedInvoices(payload),
            };
        case INVOICES_FAILED:
            return {
                ...state,
                status: FAIL,
            };
        case INVOICE_RELOAD:
            return {
                ...state,
                status: DEFAULT,
            };
        case INVOICE_UPDATE_LOADING:
            return {
                ...state,
                status: LOADING,
            };
        case INVOICE_UPDATE_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
            };
        case INVOICE_CHANGE:
            return {
                ...state,
                status: SUCCESS,
                invoices: InvoiceService.getUpdatedInvoices(state.invoices, payload),
            };
        case INVOICE_UPDATE_FAILED:
            return {
                ...state,
                status: FAIL,
                errorCode: payload.err,
            };
        default:
            return state;
    }
};

export default invoiceReducer;
